import React, { useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import {
  Dashboard,
  MerchantDashboard,
  Management,
  Reports,
  Settlements,
  Transactions,
} from '.'
import {
  Errors,
  Merchants,
  Notifications,
  Outbound,
  RatePlans,
  Rates,
  Requests,
  Templates,
  Users,
} from '../Routes/Management'
import { useAuth, IUser, IUseAuthType } from 'Auth'
import { TabMenu } from 'UI'

const Restrict = (props: any) => {
  let { redirectTo, condition, element } = props
  return condition ? element : <Navigate to={redirectTo} />
}

const AppRoutes = () => {
  const user: IUser = useAuth((state: IUseAuthType) => state.user)
  const [options, setOptions] = useState({})

  return (
    <>
      <Routes>
        <Route
          path='*'
          element={
            <Navigate
              to={user.mode === 'API' ? '/paygate' : '/dashboard'}
              replace
            />
          }
        />
        {user && user.mode === 'API' ? (
          <>
            {/* EMBEDDED CLIENT ROUTES */}
            <Route path='/paygate' element={<TabMenu />} />
            <Route
              path='paygate/summary'
              element={
                <MerchantDashboard
                  options={options}
                  setOptions={setOptions}
                  heading='Summary & Statistics'
                />
              }
            />
            <Route path='/paygate/settlements' element={<Settlements />} />
            <Route path='/paygate/transactions' element={<Transactions />} />
          </>
        ) : (
          <>
            {/* STANDARD UI-LOGIN ROUTES */}
            <Route
              path='/dashboard'
              element={
                user.type === 'MERCHANT' || user.mode === 'API' ? (
                  <MerchantDashboard
                    options={options}
                    setOptions={setOptions}
                  />
                ) : (
                  <Dashboard />
                )
              }
            />
            {/* MANAGAMENT TAB IS CONDITIONAL UPON ADMIN PRIVELLAGE */}
            <Route
              path='management'
              element={
                <Restrict
                  condition={user.type === 'ADMIN'}
                  redirectTo='/'
                  element={<Management />}
                />
              }
            >
              {/* OPERATIONAL */}
              <Route path='review' element={<Management />} />
              <Route path='review/notifications' element={<Notifications />} />
              {/* SETUP */}
              <Route path='setup' element={<Management />} />
              <Route path='setup/merchants' element={<Merchants />} />
              <Route path='setup/templates' element={<Templates />} />
              <Route path='setup/rateplans' element={<RatePlans />} />
              <Route path='setup/rates' element={<Rates />} />
              <Route path='setup/users' element={<Users />} />
              {/* LOGGING */}
              <Route path='logging' element={<Management />} />
              <Route path='logging/outbound' element={<Outbound />} />
              <Route path='logging/requests' element={<Requests />} />
              <Route path='logging/errors' element={<Errors />} />
            </Route>

            <Route path='/reports' element={<Reports />} />
            <Route path='/settlements' element={<Settlements />} />
            <Route
              path='/transactions'
              element={<Transactions options={options} />}
            />
          </>
        )}
      </Routes>
      {/* <ManagementRoutes /> */}
    </>
  )
}

export default AppRoutes
