import React, { useState, useEffect } from 'react'
import { DefaultTableStyle } from '.'
import { FaEllipsisH } from 'react-icons/fa'
import { Box, Flex, Text, Spacer } from '@chakra-ui/react'
import RDT from 'react-data-table-component'
import _get from 'lodash/get'
import PropTypes from 'prop-types'

import { useLayout } from '../Layout'

const Table = (props) => {
  const { id, crud, columns, rowHeight, children, ...o } = props
  const [rowCount, setRowCount] = useState(24)

  //Layout Store
  const layout = useLayout()
  let rowHeightCalc = rowHeight || '28px'

  let height =
    parseInt(_get(layout, 'crud_panel.height', 0)) -
    parseInt(_get(layout, 'crud_navigation_panel.height', 0)) -
    98 //64 is size of pagination area

  //const rowCount = parseInt(height / rowHeightCalc)

  const [style] = useState(
    Object.assign(DefaultTableStyle, props.mergeCustomStyles)
  )

  useEffect(() => {
    const abortController = new AbortController()
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (height > 0 && rowHeightCalc) {
      let rowHgt =
        typeof height === 'string'
          ? parseInt(rowHeightCalc.replace('px', ''))
          : parseInt(rowHeightCalc)
      let calcRowCount = parseInt(height / rowHgt)
      if (calcRowCount !== rowCount) setRowCount(calcRowCount || 24)
    }
  }, [height, rowHeightCalc, rowCount])

  const rowClicked = (row) => {
    //21/7/20 Added ability to set row._disabled property to prevent editor
    if (row && row._editordisabled && row._editordisabled === true) return
    //if (row && row._readonly) return
    if (crud.hasEditor) {
      if (row && !row[crud.keyField])
        console.error(
          `KeyField [${crud.keyField}] does not exist in row - Check keyField Value`
        )
      crud.set({
        key: row[crud.keyField],
        active: true,
      })
    }
  }

  //Display No data if data is zero length
  if (crud && crud.data && !crud.data.length && !crud.drilldown)
    return (
      <Flex direction='column' h='100%'>
        <Spacer />
        <Text textAlign={'center'} fontSize='22px'>
          No data to display ....
        </Text>
        <Spacer />
      </Flex>
    )

  if (!id) throw new Error('ID attribute is required on Table')

  //USE FULL WIDTH FLEX CONTAINER SO THAT CHILDREN FILE REQUIRED HEIGHT & TABLE FILLS REMAINDER
  //CHILDREN (TYPICALLY INFO TEXT) ARE NOT SHOW FOR MD DEVICES DOWN

  //Only User Pagination on Outer Table (It is hidden for drilldowns)
  let paginationProps =
    crud && crud.drilldown
      ? {}
      : { pagination: true, paginationPerPage: rowCount }

  return (
    <Box
      id={id}
      h='100%'
      w='100%'
      className='no-scroll-bar'
      // Scroll is allowed in Either direction but scrollbar is hidden
      overflow={'scroll'}
    >
      {crud.tableHeading}
      <Box h='100%' w='100%' id='RDT-BOX'>
        <RDT
          //To hide older checkboxes and get correctly styled boxes change this class name
          style={{
            border: '1px solid lightgray',
            borderRadius: '3px',
            overflowX: 'auto', //Allow Table to Scroll Horizonally
          }}
          id={id}
          editKey={crud.keyField}
          keyField={crud.keyField || 'id'} //Added 7/11/2021 Added to fix bug where Drilldowns remain active
          className='standard-checkbox'
          data={crud.data}
          columns={columns}
          onRowClicked={rowClicked}
          highlightOnHover
          striped
          responsive
          customStyles={style}
          rowHeight={rowHeightCalc}
          {...paginationProps}
          noHeader={crud.data && crud.data.length <= 0}
          noDataComponent={
            crud.drilldown ? <></> : <FaEllipsisH className='mt-1' />
          }
          dense
          {...o}
          title={
            crud.drilldown ? (
              <Text py={1} sx={{ fontWeight: 600 }} fontSize='md'>
                {crud.title}
              </Text>
            ) : (
              ''
            )
          }
        />
      </Box>
    </Box>
  )
}

Table.PropsTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(Table)
