import React from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react'
import { FaDatabase, FaRegClipboard } from 'react-icons/fa'

//GET FORMATTED ERROR TO OUTPUT TO KEYBOARD
const formatObject = (obj) => {
  let text = ''
  let keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (typeof obj[key] === 'object') {
      text += formatObject(obj[key])
    } else {
      text += `${String(key + ':')
        .trim()
        .padEnd(10, ' ')}${obj[key]}\n`
    }
  }
  return text
}

export const Alert = (props) => {
  const { alert } = props
  const onClose = () => alert.clearAlert()

  return (
    <Modal
      isOpen={alert && alert.error}
      onClose={onClose}
      borderRadius='5px'
      size='3xl'
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg='red.100' borderTopRadius={'5px'}>
          {alert.title ? (
            <Flex direction='row' alignItems={'center'}>
              <FaDatabase size='18' />
              <Text ml={2}>
                Database Error: [{alert.status}] {alert.title}
              </Text>
            </Flex>
          ) : (
            <></>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={5}>
          {alert.message && (
            <>
              <Text fontSize={'lg'}>
                An Error was encountered. Please review error log for more
                details
              </Text>
              <Text fontWeight='600' fontSize='lg' color='#5d0f02'>
                {alert.identity} - {alert.message}
              </Text>
            </>
          )}
        </ModalBody>

        <ModalFooter p={1} bg='gray.100' borderBottomRadius={'5px'}>
          <Button
            m={1}
            colorScheme='secondary'
            color='grey'
            variant='outline'
            leftIcon={<FaRegClipboard />}
            onClick={() => {
              let text = formatObject(alert)
              //let text = alert.map((rec, idx) => `${idx}: ${rec[idx]}\n`)
              navigator.clipboard.writeText(text)
            }}
          >
            COPY ERROR DETAILS
          </Button>
          <Button m={1} colorScheme='blue' onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
