export const colors = {
  sidebar: {
    bg: '#36454F',
    color: 'white',
  },
  menuButton: {
    bg: 'transparent',
    hover: {
      bg: '#51606B',
    },
    active: {
      bg: '#F7941E',
    },
  },
}
