import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Box } from '@chakra-ui/react'

import { useDB } from 'Library/DB'
import { useAuth } from 'Auth'
import moment from 'moment'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  scales: {
    yAxes: [{ ticks: { mirror: true } }],
  },
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
}

const RevenueChart = (props) => {
  const db = useDB()
  const user = useAuth((state) => state.user)

  const [chartData, setChartData] = useState()

  const getData = async () => {
    let result = await db.axios({
      server: 'PAYGATE',
      method: 'GET',
      url: `/api/management/dashboard/revenuechart?merchant=${user.merchant}`,
    })
    //setChartData(result.data)
    let labels = []
    let amounts = []
    let lastDate = moment()
    for (let i = 0; i < result.data.length; i++) {
      let rec = result.data[i]
      lastDate = moment()
        .year(rec.year)
        .month(rec.month - 1)
        .endOf('month')
      labels.push(lastDate.format('MMMM YYYY'))
      amounts.push(parseFloat(rec.amount))
    }
    //Add Extra records to make 12 months
    for (let i = result.data.length; i < 12; i++) {
      lastDate.subtract(1, 'month')
      labels.push(lastDate.format('MMMM YYYY'))
      amounts.push(0)
    }
    let dt = {
      labels: labels,
      datasets: [
        {
          label: '',
          data: amounts,
          borderColor: 'lightgray',
          backgroundColor: '#bae1ff',
        },
      ],
      detail: {},
    }
    setChartData(dt)
  }

  useEffect(() => {
    if (user.merchant) getData()
    // eslint-disable-next-line
  }, [user.merchant])

  return (
    <Box fontFamily={'Roboto Condensed'} h='100%' overflow={'hidden'}>
      {chartData && Object.keys(chartData).length ? (
        <Bar responsive={'true'} options={options} data={chartData} />
      ) : (
        <></>
      )}
    </Box>
  )
}

export default React.memo(RevenueChart)
