export const sizing = {
  base: {
    panelWidthOpen: 220,
    panelWidthClosed: 0,
  },
  lg: {
    panelWidthOpen: 220,
    panelWidthClosed: 0,
  },
}
