import create from 'zustand'
import { persist } from 'zustand/middleware'

export enum EnumMode {
  API = 'API',
  LOGIN = 'LOGIN',
}

export enum EnumUserType {
  ADMIN = 'ADMIN',
  MERCHANT = 'MERCHANT',
  PROVIDER = 'PROVIDER',
}

export interface IUser {
  id?: string
  name?: string
  token?: string
  type?: EnumUserType
  email?: string
  mode?: EnumMode
  merchant?: string
}

export interface IUseAuthType {
  user: IUser
  logout: boolean
  variant: string
  setUser: (user: IUser) => void
  setLogout: (flag: boolean) => void
  setVariant: (variant: string) => void
}

//NOTE AUTH STORE IS PERSISTED SO IT SURVIVES THROUGH BROWSER REFRESH
export const useAuth = create<IUseAuthType | any>(
  persist(
    (set, get) => ({
      user: {},
      logout: false,
      variant: '', //Embedded API Variant
      setUser: (user: IUser) => set({ user: user }),
      setLogout: (flag: boolean) => set({ logout: flag }),
      setVariant: (variant: string) => set({ variant }),
    }),
    {
      name: 'paygate-auth',
      getStorage: () => sessionStorage,
    }
  )
)
