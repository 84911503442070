import React, { useState } from 'react'
import { Box, Grid, Flex, Text, GridItem } from '@chakra-ui/react'
import numeral from 'numeral'

import { Header, IconBox } from 'Library'
import { FaAsterisk, FaChartLine, FaClock } from 'react-icons/fa'
import { useAuth, IUseAuthType } from 'Auth'
import { PendingSettlement, RevenueChart, MerchantNotifications } from '.'

interface IMerchantDashboardProps {
  setTabIndex?: (idx: number) => void
  options?: any
  setOptions: (obj: any) => void
  heading?: string
}

const MerchantDashboard = (props: IMerchantDashboardProps) => {
  const { setTabIndex, setOptions, heading } = props
  const user = useAuth((state: IUseAuthType) => state.user)
  const [amount, setAmount] = useState(0)

  return (
    <Box>
      {user.mode !== 'API' && (
        <Header headerText={heading ? heading : 'Dashboard'} />
      )}
      <Grid
        h='100%'
        p={2}
        gap='8px'
        templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
      >
        <GridItem>
          <IconBox
            title={<Text fontWeight='600'>Notifications</Text>}
            iconProps={{ bg: 'red' }}
            icon={<FaAsterisk size='50px' color='white' />}
          >
            <Flex
              direction='column'
              h='450px'
              w='100%'
              bg='white'
              boxShadow='5px'
              borderRadius='5px'
              p={2}
            >
              <MerchantNotifications
                user={user}
                setTabIndex={setTabIndex}
                setOptions={setOptions}
              />
            </Flex>
          </IconBox>
        </GridItem>
        <GridItem>
          <IconBox
            title={<Text fontWeight='600'>Revenue Chart</Text>}
            iconProps={{ bg: 'green' }}
            icon={<FaChartLine size='50px' color='white' />}
          >
            <Flex
              direction='column'
              h='450px'
              w='100%'
              bg='aliceblue'
              boxShadow='5px'
              borderRadius='5px'
              p={1}
            >
              <RevenueChart />
            </Flex>
          </IconBox>
        </GridItem>
        <GridItem>
          <IconBox
            title={
              <Flex direction='column'>
                <Text fontWeight='600'>Pending Settlement</Text>
                <Text
                  align='right'
                  fontWeight='600'
                  color={amount >= 0 ? 'green' : 'red'}
                >
                  {numeral(amount).format('$0,00.00')}
                </Text>
              </Flex>
            }
            icon={<FaClock size='50px' color='white' />}
          >
            <Flex
              direction='column'
              w='100%'
              h='450px'
              bg='gray.100'
              boxShadow='5px'
              borderRadius='5px'
            >
              <PendingSettlement amount={amount} setAmount={setAmount} />
            </Flex>
          </IconBox>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default React.memo(MerchantDashboard)
