import React, { useEffect, useMemo } from 'react'
import numeral from 'numeral'
import moment from 'moment'
import { Box, Flex, Text } from '@chakra-ui/react'
import _get from 'lodash/get'

import TransactionFees from './TransactionFees'

import CRUD, {
  useCRUD,
  Alert,
  useAlert,
  Table,
  MultiLineHeader,
} from 'Library/CRUD'
import { useDB, IUseDBType } from 'Library/DB'
import { useAuth, IUser, IUseAuthType } from 'Auth'

const TransactionDrilldown = (props: any) => {
  let { data, parent, refreshParent, ...o } = props
  const user: IUser = useAuth((state: IUseAuthType) => state.user)

  const db = useDB()
  const [alert, setAlert] = useAlert()

  //FACILITATE CHILD REFRESH FROM PARENT
  let childRefresh = _get(props, 'filter._childRefresh', null)
  useEffect(() => {
    if (crud && crud.refresh)
      crud.refresh({ message: 'Tran Drilldown Child Refresh' })
    // eslint-disable-next-line
  }, [childRefresh])

  const crud = useCRUD({
    id: 'transactiondrilldown',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    drilldown: true,
    filter: {
      startDate: moment().subtract(2, 'days').toDate(),
      endDate: new Date(),
      //type: 'PAYMENT',
    },
    fetch: async () => {
      //if (!filter.query) return [] //Wait until there is a filter
      let result: IUseDBType = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/transaction/fetchdetail/${data.id}?${
          parent ? `parent=${parent}` : ''
        }`,
      })
      return result.data
    },
  })

  let columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: (row: any) => row['tran_date'],
        format: (rec: any) => moment(rec.date).format('DD/MM/YYYY'),
        width: '75px',
        sortable: true,
      },
      {
        name: 'Reference',
        selector: (row: any) => row['ref'],
        sortable: true,
        width: '130px',
      },
      {
        name: 'Type',
        selector: (row: any) => row['type'],
        sortable: true,
        width: '70px',
      },
      {
        name: 'Status',
        selector: (row: any) => row['status'],
        format: (row) => (
          <Text
            fontWeight={row.status === 'ERROR' ? 'bold' : 'normal'}
            color={
              row.status === 'RECORDED'
                ? 'red'
                : row.status === 'VERIFIED'
                ? 'green'
                : ''
            }
          >
            {row.status}
          </Text>
        ),
        sortable: true,
        width: '70px',
      },
      {
        name: parent ? 'Notice' : 'Settlement ID / Notice',
        selector: (row: any) =>
          parent ? row.notice : row['settlement_id'] || row.notice,
        sortable: true,
        //width: "180px",
        //omit: parent ? true : false,
      },
      {
        name: 'Batch',
        selector: (row: any) => row['settlement_ref'],
        sortable: true,
        width: '65px',
      },
      // {
      //   name: "Notice / Reason",
      //   selector: (row: any) => row["notice"],
      //   sortable: true,
      // },
      {
        name: 'Orig Amount',
        selector: (row: any) => row['amount'],
        sortable: false,
        format: (rec: any) => numeral(rec.amount).format('0,0.00'),
        width: '90px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Settled', 'Fees']} align='right' />,
        selector: (row: any) => row['settle_fees'],
        sortable: false,
        format: (row: any) => numeral(row.settle_fees).format('0,0.00'),
        width: '90px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Expected', 'Fees']} align='right' />,
        selector: (row: any) => row['expected_fees'],
        sortable: false,
        format: (row: any) => (
          <Text
            color={row.expected_fees <= row.settle_fees ? 'green' : 'red'}
            fontWeight={row.expected_fees > row.settle_fees ? 'bold' : 'normal'}
          >
            {numeral(row.expected_fees).format('0,0.00')}
          </Text>
        ),
        width: '90px',
        right: true,
        omit: user.mode === 'API' || user.type !== 'ADMIN',
      },
      {
        name: 'Net',
        selector: (row: any) => row['amount'],
        sortable: false,
        format: (row: any) =>
          numeral(row.affect_balance ? row.amount : 0).format('0,0.00'),
        right: true,
        width: '90px',
      },
    ],
    [parent, user]
  )

  const conditionalRowStyles = [
    {
      when: (row: any) =>
        row.type === 'PAYMENT' &&
        (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'green',
        //fontWeight: 600,
      },
    },
    {
      when: (row: any) =>
        row.type === 'PREAUTH' &&
        (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'blue',
        //fontWeight: 600,
      },
    },
    {
      when: (row: any) =>
        row.type === 'REFUND' &&
        (row.status === 'VERIFIED' || row.status === 'COMPLETE'),
      style: {
        color: 'darkorange',
        //fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.status === 'ERROR',
      style: {
        color: 'red',
        //backgroundColor: '#faedf0',
        //textDecoration: 'line-through',
        // /fontWeight: 600,
      },
    },
  ]

  return (
    <Box fontFamily={'Roboto Condensed'} h='100%'>
      <CRUD id='transactiondetail' crud={crud} {...o}>
        <Alert alert={alert} setAlert={setAlert} />
        <Flex mt={2} direction='row' fontSize={14}>
          <Text mr={1}>Transaction ID:</Text>
          <Text color='blue'>{data.id}</Text>
        </Flex>
        <Flex direction='row' fontSize={14}>
          <Text>Check-In:</Text>
          <Text fontWeight='bold' ml={1}>
            {moment(data.checkin_date).format('DD/MM/YYYY')}
          </Text>
          <Text ml={2}>Check-Out:</Text>
          <Text fontWeight='bold' ml={1}>
            {moment(data.checkout_date).format('DD/MM/YYYY')}
          </Text>
          <Text ml={2}>Holding Days:</Text>
          <Text fontWeight='bold' ml={1}>
            {moment(data.checkin_date).diff(moment(data.date), 'days')}
          </Text>
        </Flex>
        <Table
          id='transaction_drilldown'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
          conditionalRowStyles={conditionalRowStyles}
          expandableRows
          expandableRowsComponent={TransactionFees}
        ></Table>
        {/* <Button
        onClick={() => {
          refreshParent()
        }}
      >
        Refresh Parent
      </Button> */}
      </CRUD>
    </Box>
  )
}

export default React.memo(TransactionDrilldown)
