/**********************************************
 **** useCRUD Hook - New Version July 2022 ****
 **********************************************
 12/07/2022 Modified to Work as self standing library outside of Owner Accounting
 useCrud now returns single value which includes record (rather than [crud,record])
 28/07/2022 Include Filter hook inside of CRUD
 */
import React, { useState, useEffect } from 'react'
import immer from 'immer'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _isDate from 'lodash/isDate'
import moment from 'moment'
import _filter from 'lodash/filter'
import { useToast } from '@chakra-ui/react'
import { _search } from '../Utils'
import { useCRUDStore } from './useCRUDStore'
import { ulid } from 'ulid'

//let count = 0

export const useCRUD = (definition, _defaults) => {
  const toast = useToast()
  const store = React.useCallback(useCRUDStore(), [])
  const [filterInit, setFilterInit] = useState(false)
  const [fetchCount, setFetchCount] = useState(0)
  const [filter, setFilterFn] = useState(_get(definition, 'filter', {}))
  const [lastQuery, setLastQuery] = useState('')
  const [searchText, setSearchText] = useState('')
  const [inactive, setInactive] = useState(false)
  const [fetchData, setFetchData] = useState([])
  const [deleted, setDeleted] = useState([])
  const [deleted2, setDeleted2] = useState([])
  const [deleted3, setDeleted3] = useState([])
  const [extra, setExtra] = useState([])
  const [data, setData] = useState([])
  const [filterUpdated, setFilterUpdated] = useState(false)

  //=====================================
  // MERGE PROVIDER OPTIONS WITH DEFAULTS
  //=====================================
  const [options, setOptions] = useState({
    id: null,
    title: <></>,
    tableHeading: <></>,
    icon: <></>,
    record: {},
    data: [],
    schema: {},
    defaults: {},
    hasTable: true,
    hasEditor: true,
    showInactive: false,
    drilldown: false,
    readonly: false,
    waitRefresh: false,
    waitFilter: false,
    submitRef: null,
    filter: {},
    //PLACEHOLDERS FOR STANDARD CALLBACKS
    fetch: async () => {},
    postFetchCallback: (rec) => rec,
    preSubmit: (rec) => rec,
    postSubmit: (rec) => rec,
    ...definition,
  })

  //===============
  // QUERY HANDLERS
  //===============
  const getQuery = React.useCallback((obj, pick, omit) => {
    let count = 0
    let qry = ''
    let values = obj
    if (pick) values = _omit(values, omit)
    if (omit) values = _pick(values, omit)

    for (let key in values) {
      //Exclude any keys that start with _
      if (!key.startsWith('_') || key === '_refresh') {
        let val = values[key]
        if (typeof val === 'object' && _isDate(val))
          val = moment(val).format('YYYY-MM-DD')
        if (key !== 'search' && val) {
          qry = qry + (count ? `&${key}=${val}` : `?${key}=${val}`)
          count++
        }
      }
    }
    return qry
  }, [])

  //--------------
  // DATA HANDLERS
  //--------------
  const getData = React.useCallback(
    async (qry, callback) => {
      if (options && options.fetch) {
        let data = await options.fetch(qry)

        if (data) {
          setFetchData(data)
          //18/6/2021 Changes for Show Inactive
          let hasInactive =
            data && data[0] && typeof data[0].inactive !== 'undefined'
          let dt
          if (hasInactive)
            dt =
              options && options.showInactive
                ? data
                : _filter(data, { inactive: false })
          else dt = data

          if (searchText) {
            //2022-07-17 Do not call filterData (Which will use setData) - instead to search here
            dt = _search(dt, searchText)
          }
          if (callback) {
            //CASCADE UPDATES
            //When Get Data is called with callback then make sure new data set is returned to force refresh
            //Note Setting of Data is done by callback
            setData([...dt])
            callback(dt)
          } else {
            setData(dt)
          }
          //22-06-22 Added Post Fetch Callback (Can be set in useCRUD)
          if (options && options.postFetchCallback)
            options.postFetchCallback(data)
        }
      }
    },
    [options, searchText]
  )

  const filterData = React.useCallback(
    (searchText = '', altData = null) => {
      //2022-06-22 ADDED OPTION TO PASS IN ALTERNATE FETCH DATA IN CASE OF CASCADE UPDATE WHERE SEARCH FILTER IN PLACE
      if (altData && altData.length) {
        let data = _search(altData, searchText)
        setData(data)
      } else if (fetchData && fetchData.length) {
        let data = _search(fetchData, searchText)
        setData(data)
      }
    },
    [fetchData]
  )

  const filterDataFn = React.useCallback(
    (fn) => {
      if (fetchData && fetchData.length) {
        let data = []
        for (let i = 0; i < fetchData.length; i++) {
          let rec = fetchData[i]
          if (fn(rec)) data.push(rec)
        }
        setData(data)
      }
    },
    [fetchData]
  )

  const onRead = React.useCallback(
    async (key) => {
      setDeleted([]) // 9/7/21 Clear any prior deletions held in state
      setDeleted2([]) // 5/1/22 Extra Deletions Array
      setDeleted3([])
      if (options && options.read) {
        let result = await options.read(key)
        if (result && typeof result._allowDelete === 'undefined')
          result._allowDelete = false
        setRec(result.data)
        return result.data
      }
    },
    [options]
  )

  //============================
  // CRUD STATE AND RECORD STATE
  //============================
  const [rec, setRec] = useState(options.record)
  const [state, setState] = useState(options)

  //GENERAL CRUD SETTERS
  const set = React.useCallback(
    (obj) => {
      setState({ ...state, ...obj })
    },
    [state]
  )

  const toggleInactive = React.useCallback(() => {
    setInactive(!inactive)
  }, [inactive])

  //SETTER FOR RECORD
  const setRecord = React.useCallback(
    (record) => {
      setRec({ ...rec, ...record })
    },
    [rec]
  )

  /*************************************************************
     *** setValue() - Set Value in Record Object (Using Immer) ***
     *************************************************************
     Obj may be a single object eg: {field: 'door', value: '36'}
     or an array [{field: 'door', value: '21'}, {field: 'link_ref', value: 123]
     nest is [nestname, index]
    */
  const setValue = React.useCallback(
    (obj, nest = null) => {
      //SET OBJECT VALUES FOR EACH OBJECT PAIR
      const set = (rec, obj, nest) => {
        let fields = Object.keys(obj)
        for (let idx in fields) {
          let field = fields[idx]
          let val = obj[field]
          //SET VALUE IN IMMER OBJECT (SIMPLE & NESTED)
          if (nest) {
            let nestname = nest[0]
            let idx = nest[1]
            rec[nestname][idx][field] = val
          } else {
            rec[field] = val
          }
        }
      }
      //Set Record field values to Immutable record (via immer)
      let immerRec = immer(rec, (newRec) => {
        if (obj && obj.length) {
          for (let i in obj) {
            set(newRec, obj[i], nest)
          }
        } else {
          //SINGLE OBJECT
          set(newRec, obj, nest)
        }
      })
      setRec(immerRec)
    },
    [rec]
  )

  /********************************************************************************************
   **** updateFilter() - UPDATE EXISTTING FILTER WITH NEW VALUES (USE setFitler to Replace) ***
   ********************************************************************************************/
  const setFilter = React.useCallback((obj) => {
    setFilterFn(() => {
      setFilterUpdated(true)
      return { obj }
    })
  }, [])
  const updateFilter = React.useCallback((obj) => {
    setFilterUpdated(true)
    setFilterFn((prevState) => {
      return { ...prevState, ...obj }
    })
  }, [])

  /************************************************************************************
   *** getValue() - Gets value of named field from record (options to pass in nest) ***
   *************************************************************************************/
  const getValue = React.useCallback(
    (field, nest, ifzero = null) => {
      let val = nest
        ? _get(rec, `${nest[0]}[${nest[1]}].${[field]}`, '')
        : _get(rec, `${[field]}`, '')
      if (typeof ifzero === 'undefined' || ifzero === null)
        val = parseFloat(val) === 0 ? ifzero : val
      //Prevent problems with null fields
      if (val === null) {
        if (typeof val === 'number') val = 0
        else val = ''
      }
      return val
    },
    [rec]
  )

  /**********************************************************************
   *** clearRecord() - Clear Record and assign defaults (using Immer) ***
   **********************************************************************/
  const clearRecord = React.useCallback(() => {
    const { defaults } = setOptions
    if (defaults) setRec(defaults)
    else setRec({})
  }, [])

  /************************************************
   *** addRecord (record) - Add Record Function ***
   ************************************************/
  const addRecord = React.useCallback(
    (record) => {
      set({ key: null, active: true })
      clearRecord()
    },
    [clearRecord, set]
  )

  //==============================================================================
  // CRUD.REFRESH() - FORCE REFRESH OF DATA (EVEN IF UNDERLYING QUERY IS THE SAME)
  //==============================================================================
  //Note: CASCADE OPTION WILL CAUSE _childRefresh to be set on Props which can be used to trigger drilldown refresh
  const refresh = React.useCallback(
    async (options) => {
      //Show Refresh Source
      if (options && options.message)
        console.info(`info() - REFRESH - ${options.message}`)
      //If filter option provided then use this as filter (eg: crud.storeFilter may be passed to use session Storage value)
      let obj = options && options.filter ? options.filter : {}
      obj._refresh = ulid()
      updateFilter(obj)
    },
    [updateFilter]
  )

  const onDelete = React.useCallback(
    async (key) => {
      let result = await options.delete(key)
      set({ active: false })
      toast({
        title:
          result && result.error
            ? `Error Deleting Record!`
            : `Delete Succesfull!`,
        description: result.message || '',
        status: result && result.error ? 'error' : 'warning',
        duration: 4000,
        isClosable: true,
      })
      refresh()
    },
    [options, refresh, set, toast]
  )

  if (options.editorOnly) options.active = true //Force editor open with editorOnly

  //========================================================================
  // HOOK TO GET DATA WHENEVER FILER QUERY CHANGES (UPDATES SESSION STORAGE)
  //========================================================================
  useEffect(() => {
    //ON FIRST LOAD - USE FILTER SUPPLIED TO useCRUD()
    let hasFilter = Object.keys(filter).length ? true : false
    let newQuery = getQuery(filter)
    //Store Filter in Session Storage (can be used to get store filter if memory value resets - useful for post axios updates)
    store.setState(options.id, {
      ...filter,
      _query: newQuery,
      _queryCount: 0,
    })
    setFilterInit(true)
    let getNow = options.waitRefresh ? _get(filter, '_refresh', false) : true
    if (getNow) getNow = options.waitFilter ? filterUpdated : true // Can be used to wait until filter has been set by setFilter or update Filter call
    if (getNow)
      getNow = newQuery !== lastQuery || (!hasFilter && fetchCount === 0)
    if (getNow) {
      getData(newQuery)
      setLastQuery(newQuery)
      setFetchCount(fetchCount + 1)
    }
    // xeslint-disable-next-line
  }, [
    filter,
    filterInit,
    fetchCount,
    getData,
    getQuery,
    lastQuery,
    options.id,
    options.waitRefresh,
    options.waitFilter,
    filterUpdated,
    store,
  ])

  //=============================
  // TRIGGER HOOK FOR SEARCH TEXT
  //=============================
  useEffect(() => {
    filterData(searchText)
  }, [searchText, filterData])

  //==================================
  // TRIGGER HOOK FOR INACTIVE RECORDS
  //==================================
  useEffect(() => {
    filterDataFn((rec) => (inactive ? true : rec.inactive === false))
    //Override - Adding filterrDataFn causes not to be displayed - need to sort this out at some stage
    // eslint-disable-next-line
  }, [inactive])

  /****************************************************************
   *** New Submit Methods - Added in Refactored CRUD 2022/07/19 ***
   *****************************************************************/
  //Added 2022-07-18 Send Submit Record Request (Via Submit Button  with ref provided by Form component)
  const requestSubmit = React.useCallback(() => {
    if (state && state.submitRef) state.submitRef.click()
  }, [state])

  //SAVE THE RECORD --> CALLED BY CHILD COMPONENT obSubmit AFTER SUBMIT HAS PASSED requestSubmit Test via react-hook-form handleSubmit
  const submit = React.useCallback(
    async (submitRec) => {
      //DO PRE SUBMIT
      let newRec = options.preSubmit(submitRec)
      //SUBMIT RECORDS
      let result
      if (state.key) {
        result = await state.update(newRec)
      } else {
        result = await state.create(newRec)
      }
      //DO POST SUBMIT
      if (result && !result.error) {
        newRec = await options.postSubmit(newRec)
        setRec(newRec)
      }
      refresh()

      //Show Update
      toast({
        title:
          result && result.error
            ? `Record Submit Failed!`
            : `Record Successfully Submitted!`,
        description: result.message || '',
        status: result.error ? 'error' : 'success',
        duration: 4000,
        isClosable: true,
      })

      //Return with API Call result plus record value in the data field
      result.data = newRec
      return result
    },
    [options, refresh, state, toast]
  )

  //================================================================
  // THROW CONSOLE ERROR IF REQUIRED VALUES ARE NOT PASED TO useCRUD
  //================================================================
  if (!options.id) throw new Error('id MUST BE PASSED TO useCRUD()')
  if (!options.keyField) throw new Error('keyfield must be provided to useCRUD')
  if (options.hasTable) {
    if (!options.fetch)
      throw new Error(
        `fetch options must be supplied to useCRUD (or set hasTable option to false)`
      )
  }
  if (options.hasEditor) {
    if (!options.read)
      throw new Error(
        `read options must be supplied to useCRUD (or set hasEditor to false)`
      )
    if (!options.create && !options.hasCreate)
      throw new Error(
        `create options must be supplied to useCRUD (or set hasEditor or hasCreate to false)`
      )
    if (!options.update && !options.hasUpdate)
      throw new Error(
        `update options must be supplied to useCRUD (or set hasEditor or hasUpdate to false)`
      )
    if (!options.delete && !options.hasDelete)
      throw new Error(
        `delete options must be supplied to useCRUD (or set hasEditor or hasDelete  to false)`
      )
  }

  //RETURN WITH (1) CRUD OBJECT (CONTAINING METHODS & VALUES) AND (2) RECORD VALUES
  //
  return React.useMemo(
    () => ({
      ...state,
      set,
      onRead,
      onDelete,
      data,
      deleted,
      setDeleted,
      deleted2,
      setDeleted2,
      deleted3,
      setDeleted3,
      extra,
      setExtra,
      searchText,
      setSearchText,
      toggleInactive,
      inactive,
      refresh,
      setRecord,
      clearRecord,
      setValue,
      getValue,
      setFetchData,
      addRecord,
      requestSubmit,
      submit,
      filter,
      storeFilter: store[options.id],
      setFilter,
      updateFilter,
      record: rec,
    }),
    [
      state,
      set,
      onRead,
      onDelete,
      data,
      deleted,
      setDeleted,
      deleted2,
      setDeleted2,
      deleted3,
      setDeleted3,
      extra,
      setExtra,
      searchText,
      setSearchText,
      toggleInactive,
      inactive,
      refresh,
      setRecord,
      clearRecord,
      setValue,
      getValue,
      setFetchData,
      addRecord,
      requestSubmit,
      submit,
      filter,
      setFilter,
      updateFilter,
      rec,
      options,
      store,
    ]
  )
}
