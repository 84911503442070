import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import Auth, { useAuth } from 'Auth'
import Store, { useStore } from 'Store'
import DB, { useDB } from 'Library/DB'
import UI from 'UI'
import Routes from 'Routes'
import theme from './Theme'
import './App.css'
import 'react-datepicker/dist/react-datepicker.css'

let renderCount = 0

const App = () => {
  let auth = useAuth()
  let store = useStore()
  let db = useDB()

  //Check for Embedded Mode (Run in Iframe)
  let apiLogin = window.location.search === '?api'

  renderCount++
  //console.log('RenderCount', renderCount)

  return (
    <ChakraProvider theme={theme}>
      <DB db={db}>
        <Auth auth={auth} apiLogin={apiLogin}>
          <Store store={store}>
            <BrowserRouter>
              <UI>
                <Routes />
              </UI>
            </BrowserRouter>
          </Store>
        </Auth>
      </DB>
    </ChakraProvider>
  )
}

export default App
