import React from 'react'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { useAuth, IUser, IUseAuthType } from 'Auth'

import theme from 'Theme'

interface Props {
  id: string
  children?: JSX.Element | string
  icon?: JSX.Element
  active?: boolean
  h?: number
  onClick?: any
  menuItems?: any
  preventActive?: boolean
  adminOnly?: boolean
}

export const MenuButton: React.FC<Props> = React.forwardRef<
  HTMLInputElement,
  Props
>(
  (
    {
      id,
      children,
      icon,
      h,
      onClick,
      menuItems,
      preventActive,
      adminOnly = false,
    },
    ref
  ) => {
    const user: IUser = useAuth((state: IUseAuthType) => state.user)

    const [menuItem, setmenuItem] = menuItems

    const doClick = (e: any) => {
      if (menuItems) setmenuItem(id)
      if (onClick) onClick(e)
    }

    let active = menuItem === id && !preventActive

    if (adminOnly && user.type !== 'ADMIN') return <></>
    return (
      <Flex
        ref={ref}
        direction='column'
        mr={2}
        alignContent={'start'}
        alignItems={'start'}
        onClick={(e: any) => doClick(e)}
      >
        <Grid
          templateColumns='32px 1fr'
          alignContent={'center'}
          w='100%'
          pl={'14px'}
          sx={{
            borderLeftRadius: '0px',
            borderRightRadius: '5px',
          }}
          bg={
            active
              ? theme.colors.menuButton.active.bg
              : theme.colors.menuButton.bg
          }
          h={h || '44px'}
          _hover={{
            background: active
              ? theme.colors.menuButton.active.bg
              : theme.colors.menuButton.hover.bg,
            active: { fontWeight: 'bold' },
            opacity: active ? 0.8 : 1,
          }}
        >
          <GridItem alignSelf={'center'}>{icon}</GridItem>
          <GridItem>
            <Flex direction='column' pl={2} alignItems='start' fontWeight={500}>
              {children}
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    )
  }
)
