import React from 'react'
import { Box, BoxProps, useStyles } from '@chakra-ui/react'

export const CardHead: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props
  const styles = useStyles()

  return (
    <Box __css={styles.head} {...rest}>
      {children}
    </Box>
  )
}
