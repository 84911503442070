import React from 'react'
import {
  Box,
  forwardRef,
  BoxProps,
  useMultiStyleConfig,
  StylesProvider,
  SystemProps,
  HTMLChakraProps,
} from '@chakra-ui/react'

import { CardHead } from './CardHead'
import { CardHeadInner } from './CardHeadInner'
import { CardTitle } from './CardTitle'
import { CardDescription } from './CardDescription'
import { CardRightActions } from './CardRightActions'
import { CardLeftActions } from './CardLeftActions'
import { CardLeftButton } from './CardLeftButton'
import { CardRightButton } from './CardRightButton'
import { CardBody } from './CardBody'

export interface CardProps extends Omit<BoxProps, 'title'> {
  /**
   * border variant still uses shadow but has spread of 1px to mimic the border
   * @default default
   */
  variant?: 'default' | 'header' | 'summary'
  size?: 'sm' | 'md' | 'lg'
  colorScheme?: 'white' | 'blue' | 'orange' | 'red' | 'yellow' | 'green'
  title?: React.ReactNode
  description?: React.ReactNode
  leftActions?: React.ReactNode
  leftActionsSpacing?: SystemProps['margin']
  rightActions?: React.ReactNode
  rightActionsSpacing?: SystemProps['margin']
  actionsSpacing?: SystemProps['margin']
  leftButtonIcon?: React.ReactNode
  rightButtonIcon?: React.ReactNode
  onLeftButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onRightButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  isBordered?: boolean
  isHeaderBordered?: boolean
  isActive?: boolean
  space?: SystemProps['padding']
  headProps?: BoxProps
  titleProps?: BoxProps
  descriptionProps?: BoxProps
  rightButtonProps?: HTMLChakraProps<'button'>
  leftButtonProps?: HTMLChakraProps<'button'>
  bodyProps?: BoxProps
  [propName: string]: any
}

export const Card: any = forwardRef((props, ref) => {
  const {
    variant = 'default',
    size = 'md',
    colorScheme = 'white',
    children,
    title,
    description,
    rightActions,
    rightActionsSpacing,
    leftActions,
    leftActionsSpacing,
    rightButtonIcon,
    leftButtonIcon,
    actionsSpacing,
    space,
    isBordered = false,
    isActive,
    isHeaderBordered,
    headProps,
    titleProps,
    leftButtonProps,
    rightButtonProps,
    descriptionProps,
    bodyProps,
    onLeftButtonClick,
    onRightButtonClick,
    ...rest
  } = props
  const hasHead =
    title ||
    description ||
    leftActions ||
    rightActions ||
    leftButtonIcon ||
    rightButtonIcon
  const hasBody = children ? true : false
  const hasLeftButton = leftButtonIcon ? true : false
  const hasRightButton = rightButtonIcon ? true : false
  let styles = useMultiStyleConfig('Card', {
    variant,
    size,
    colorScheme,
    space,
    isBordered,
    isActive,
    isHeaderBordered,
    hasHead,
    hasBody,
    hasLeftButton,
    hasRightButton,
  })

  return (
    <Box ref={ref} __css={styles.card} {...rest}>
      <StylesProvider value={styles}>
        {hasHead && (
          <CardHead {...headProps}>
            {leftButtonIcon && (
              <CardLeftButton {...leftButtonProps} onClick={onLeftButtonClick}>
                {leftButtonIcon}
              </CardLeftButton>
            )}
            {leftActions && (
              <CardLeftActions
                spacing={actionsSpacing || leftActionsSpacing || '8px'}
              >
                {leftActions}
              </CardLeftActions>
            )}
            <CardHeadInner>
              {title && <CardTitle {...titleProps}>{title}</CardTitle>}
              {description && (
                <CardDescription {...descriptionProps}>
                  {description}
                </CardDescription>
              )}
            </CardHeadInner>
            {rightActions && (
              <CardRightActions
                spacing={actionsSpacing || rightActionsSpacing || '8px'}
              >
                {rightActions}
              </CardRightActions>
            )}
            {rightButtonIcon && (
              <CardRightButton
                {...rightButtonProps}
                onClick={onRightButtonClick}
              >
                {rightButtonIcon}
              </CardRightButton>
            )}
          </CardHead>
        )}
        <CardBody {...bodyProps}>{children}</CardBody>
      </StylesProvider>
    </Box>
  )
})
