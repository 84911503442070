import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'

export const BtnAddRecord = (props) => {
  const { crud, label = 'Add Record', color, ...otherProps } = props
  return (
    <Button
      variant='solid'
      colorScheme='dark'
      leftIcon={<FaPlus color='white' />}
      id='btn-add-record'
      onClick={() => crud.addRecord()}
      {...otherProps}
    >
      {label}
    </Button>
  )
}
