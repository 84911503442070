import React from 'react'
import ReactDOM from 'react-dom/client'
import '@fontsource/public-sans/100.css'
import '@fontsource/public-sans/200.css'
import '@fontsource/public-sans/300.css'
import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'
import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'
import '@fontsource/public-sans/800.css'
import '@fontsource/public-sans/900.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
//Note - Strict Mode Causing render twice (normal behaviour) - disabled to prevent this
//https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
