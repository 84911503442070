import React, { useEffect } from 'react'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'

import { useDB, IUseDBType } from 'Library/DB'
import { useAuth, IUseAuthType } from 'Auth'

interface Props {
  children?: JSX.Element
  store?: any
}

const Store: React.FC<Props> = ({ store, children }) => {
  const user = useAuth((state: IUseAuthType) => state.user)
  //Load DB
  const db = useDB()

  //Load Lookup & Control Values
  const loadLookups = async () => {
    let result: IUseDBType = await db.axios({
      server: 'PAYGATE',
      method: 'GET',
      url: `/api/control/lookup`,
    })

    //Place Lookups in ZuStand Session Storage
    let keys = Object.keys(_get(result, 'data', {}))
    keys.forEach((key) => {
      store.setLookup(key, _keyBy(_get(result, `data.${key}`, []), 'code'))
    })
  }

  useEffect(() => {
    loadLookups()
    //es-lint override - only want to load lookups on browser refresh
    // eslint-disable-next-line
  }, [user.merchant])
  //if (store && !store.panelWidth) return <></>
  return <>{children}</>
}

export default React.memo(Store)
