import React, { useState, useEffect } from 'react'
import {
  Input,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
} from '@chakra-ui/react'
import { FaSearch, FaTimes } from 'react-icons/fa'

export const FilterInputSearch = ({ crud, ...o }) => {
  const [searchText, setSearchText] = useState('')
  useEffect(() => {
    if (searchText.length > 2) crud.setSearchText(searchText)
    else crud.setSearchText('')
    // eslint-disable-next-line
  }, [searchText])
  return (
    <InputGroup size='md'>
      <InputLeftAddon children={<FaSearch color='gray' />} />
      <Input
        placeholder='Search Text (min 3 chars)'
        onChange={(e) => {
          setSearchText(e.target.value)
        }}
        value={searchText}
      />
      <InputRightAddon
        children={
          <FaTimes
            color='gray'
            onClick={() => {
              setSearchText('')
            }}
          />
        }
      />
    </InputGroup>
  )
}
