import React, { useEffect } from 'react'

import { Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAuth, IUseAuthType } from 'Auth'
import { Settlements, MerchantDashboard, Transactions } from 'Routes'

interface IProps {
  children?: React.ReactNode
}

const EmbedMenu = (props: IProps) => {
  const navigate = useNavigate()
  const variant = useAuth((state: IUseAuthType) => state.variant)

  //Display Waiting Spinner when waiting for session token
  //Note: Changed away from using naviagte and routes as it there was noticible delay and inconsistent
  switch (variant) {
    case 'SUMMARY':
      return <MerchantDashboard setOptions={() => {}} />
    case 'SETTLEMENT':
      return <Settlements />
    case 'TRANSACTION':
      return <Transactions />
    default:
      return <></>
  }
}

export default EmbedMenu
