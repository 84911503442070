import React from 'react'
import { Grid } from '@chakra-ui/react'
import { Layout } from 'Library/CRUD/Layout'

const Filters = (props) => {
  const { filter, children, templateColumns, ...o } = props
  return (
    <Layout id='crud_navigation_panel' variant='panel'>
      {(dimensions, setDimensions) => (
        <Layout.Area id='crud_navigation_panel-area' as='PANEL' overflow='auto'>
          <Grid
            p={0}
            m={0}
            gap='5px'
            w='100%'
            templateColumns={templateColumns}
            {...o}
          >
            {children}
          </Grid>
        </Layout.Area>
      )}
    </Layout>
  )
}

export default Filters
