/********************************
 **** C R U D  L I B R A R Y ****
 ********************************
 12/07/2022 - Convert CRUD Library from Owner Accounting for Use As Self Standing Library in PayGate
 */
import React, { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { Layout, Header } from './Layout'
import { Progress } from './Progress'
import { _clear } from './Utils'

const CRUD = (props) => {
  const {
    children,
    filters,
    extraFilters,
    totals,
    crud,
    record,
    header,
    id,
    progress,
    ...o
  } = props

  /***************
   **** HOOKS ****
   ***************/

  useEffect(() => {
    const abortController = new AbortController()
    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    if (crud.editorOnly === true) {
      crud.setRecord(_clear(record))
      //crud.setRecord({})
      crud.set({ key: null, active: true })
      crud.set({ active: true })
    }
  }, [crud, record])

  /**************************
   *** Main Component JSX ***
   **************************/

  //For Editor Only Just display Modal
  if (crud.editorOnly) {
    return <>{children}</>
  }

  /***************************************************
   *** CRUD OUTER WRAPPER (Excluded for Drilldown) ***
   ***************************************************/
  if (crud.drilldown) {
    return <Box {...o}>{children}</Box>
  } else {
    return (
      <Layout id={`crud_wrapper_${id}`} crud={crud} variant='card' {...o}>
        {(dimensions, setDimensions) => (
          <>
            {crud.title ? (
              <Layout.Area id='crud_head' as='HEAD'>
                <Header headerText={crud.title} fontFamily={'Roboto'} />
                {header}
              </Layout.Area>
            ) : (
              <></>
            )}
            <Layout.Area id='crud_hnav' p={0} m={0} as='HNAV'>
              <Box
                m={0}
                p={1}
                bg='white'
                style={{
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                }}
              >
                {filters}
                {extraFilters}
                {totals}
              </Box>
              {progress ? (
                <Progress mx={2} progress={progress} variant='inline' />
              ) : (
                <></>
              )}
            </Layout.Area>

            <Layout.Area
              id='crud_main'
              as='MAIN'
              overflow='auto'
              p={1}
              m={0}
              style={{
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
              bg='white'
            >
              <Box
                id='crud_inner_container'
                //p={1} no padding here or horizonal scrollbars appear
                h='100%'
                borderRadius='5px'
                border='1px solid #DEDEDE'
                //shadow='sm'
                // style={{
                //   borderBottomLeftRadius: '5px',
                //   borderBottomRightRadius: '5px',
                // }}

                // style={{
                //   borderBottomLeftRadius: '5px',
                //   borderBottomRightRadius: '5px',
                // }}

                //shadow='xs' // Box Shadow for Crud Inner Container
              >
                {/* WRAP IN LAYOUT CONTROL TO ACHEIVE CUSTOM SCROLLBARS (AND GUTTER SO CONTEWNT DOES NOT FLOW ENTIRELY TO BORDER) */}
                <Layout id='crud_panel' variant='panel'>
                  {(dimensions, setDimensions) => (
                    <Layout.Area
                      id='crud-content-panel'
                      as='PANEL'
                      overflow='auto'
                    >
                      {children}
                    </Layout.Area>
                  )}
                </Layout>
              </Box>
            </Layout.Area>
          </>
        )}
      </Layout>
    )
  }
}

CRUD.PropsTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(CRUD)
