import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Flex,
  Text,
  Spacer,
  Avatar,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { Logo, GuestPointCompactLogo } from 'Assets'
import { useAuth, IUseAuthType } from 'Auth'
import { MenuButton } from '.'
import { Icon } from 'Library'

interface Props {
  children?: JSX.Element
}

export const Menu: React.FC<Props> = () => {
  const navigate = useNavigate()
  //const store = useStore()
  const panelWidth = 200
  const user = useAuth((state: IUseAuthType) => state.user)
  const menuItem = useState('Dashboard')

  let setLogout = useAuth((state: IUseAuthType) => state.setLogout)
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <Box
      fontFamily={'Roboto Condensed'}
      sx={{ overflow: 'hidden' }}
      h='100%'
      bg='sidebar.bg'
      color='sidebar.color'
    >
      <Flex h='100%' py={2} direction='column' w={panelWidth}>
        <Flex ml={1} direction='row' w={panelWidth - 50}>
          <GuestPointCompactLogo />
          <Box w='100%' p={2}>
            <Logo />
          </Box>
        </Flex>
        <Text
          pr={4}
          ml={1}
          style={{ marginTop: '-18px', marginLeft: 38 }}
          w={panelWidth - 55}
          fontSize={14}
          color='white'
          align='right'
        >
          Payment Gateway
        </Text>
        <Flex h={25} />
        <MenuButton
          id='Dashboard'
          menuItems={menuItem}
          icon={<Icon variant='Dashboard' size={6} />}
          onClick={() => navigate('/')}
        >
          Dashboard
        </MenuButton>
        <MenuButton
          id='Settlements'
          menuItems={menuItem}
          icon={<Icon variant='CalendarEvent' size={6} />}
          onClick={() => navigate('/settlements')}
        >
          Settlements
        </MenuButton>
        <MenuButton
          id='Transactions'
          menuItems={menuItem}
          icon={<Icon variant='CreditCard' size={6} />}
          onClick={() => navigate('/transactions')}
        >
          Transactions
        </MenuButton>

        <MenuButton
          id='Management'
          menuItems={menuItem}
          adminOnly={true}
          icon={<Icon variant='AccountsManagement' size={6} />}
          onClick={() => navigate('/management')}
        >
          Management
        </MenuButton>
        <MenuButton
          id='Reports'
          menuItems={menuItem}
          icon={<Icon variant='File' size={6} />}
          onClick={() => navigate('/reports')}
        >
          Reports
        </MenuButton>
        <Spacer />
        <Popover isOpen={isOpen} matchWidth={true} onClose={onClose}>
          <PopoverTrigger>
            <MenuButton
              id='User'
              preventActive={true}
              menuItems={menuItem}
              onClick={onToggle}
              h={55}
              icon={<Avatar size='sm' name={user.name} />}
            >
              <Flex direction='column' pl={1} alignItems='start'>
                <Text fontWeight={400}>{user.name}</Text>
                <Text fontSize={12}>{user.id}</Text>
              </Flex>
            </MenuButton>
          </PopoverTrigger>
          <Portal>
            <PopoverContent ml={1} sx={{ marginRight: '16px' }} w='100%'>
              <PopoverArrow />
              <PopoverHeader p={0}>
                <Button
                  justifyContent='flex-start'
                  p={0}
                  leftIcon={
                    <Box ml={2}>
                      <Icon variant='Links' size={5} color='red.500' mr={2} />
                    </Box>
                  }
                  w='100%'
                  fontWeight={700}
                  onClick={() => setLogout(true)}
                >
                  Logout
                </Button>
              </PopoverHeader>
              <PopoverBody>
                <Text color='gray'></Text>Version 1.0.2
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </Box>
  )
}

export default Menu
