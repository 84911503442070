import React, { useMemo } from 'react'
//import numeral from 'numeral'
//import moment from 'moment'
import { GridItem, Flex, Box, Text } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Editor,
  Alert,
  useAlert,
  Table,
  FilterInputSearch,
  Filters,
  BtnRefresh,
  BtnAddRecord,
  InactiveFlag,
} from 'Library/CRUD'
import { FaFlag, FaTimes, FaCheck } from 'react-icons/fa'

import { useDB, IUseDBType } from 'Library/DB'
import { TemplateEdit } from './TemplateEdit'

export const Templates = () => {
  const db = useDB()
  const [alert, setAlert] = useAlert()

  // eslint-disable-next-line
  const crud = useCRUD({
    id: 'templates',
    title: 'Provider Templates',
    hasEditor: true,
    keyField: 'id',
    setAlert: setAlert,
    editor: {
      title: 'Template',
    },
    fetch: async () => {
      let result: IUseDBType = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/template/fetch`,
      })
      return result.data
    },
    create: async (rec: any) => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/template/create`,
        data: rec,
      })
      return result
    },
    read: async (key: string) => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/template/read/${key}`,
      })
      return result
    },
    update: async (rec: any) => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/template/create`,
        data: rec,
      })
      return result
    },
    delete: async (key: string) => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'DELETE',
        url: `/api/management/template/delete/${key}`,
      })
      return result
    },
  })

  let columns = useMemo(
    () => [
      {
        name: <FaFlag />,
        selector: (row: any) => row['inactive'],
        format: (row: any) => <InactiveFlag flag={row.inactive} />,
        width: '25px',
        omit: !crud.inactive,
      },
      {
        name: 'Provider',
        selector: (row: any) => row['provider'],
        sortable: true,
        width: '120px',
      },
      {
        name: 'Tran Type',
        selector: (row: any) => row['tran_type'],
        width: '125px',
      },

      {
        name: 'Provider Name',
        selector: (row: any) => row['name'],
        sortable: true,
      },
      {
        name: 'Template',
        selector: (row: any) => row['template_length'],
        cell: (row: any) => (
          <Flex direction='row' alignItems={'center'}>
            <Box mr={2}>
              {row.template_length ? (
                <FaCheck color='green' />
              ) : (
                <FaTimes color='red' />
              )}
            </Box>
            <Text>
              {row.template_length ? `${row.template_length} chars` : ''}
            </Text>
          </Flex>
        ),
        width: '200px',
      },
      {
        name: 'Reponse Map',
        selector: (row: any) => row['mapping_length'],
        cell: (row: any) => (
          <Flex direction='row' alignItems={'center'}>
            <Box mr={2}>
              {row.mapping_length ? (
                <FaCheck color='green' />
              ) : (
                <FaTimes color='red' />
              )}
            </Box>
            <Text>
              {row.mapping_length ? `${row.mapping_length} chars` : ''}
            </Text>
          </Flex>
        ),
        width: '200px',
      },
    ],
    [crud.inactive]
  )

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          '400px 1fr 150px 150px',
        ]}
      >
        <FilterInputSearch crud={crud} />
        <GridItem />
        <BtnRefresh crud={crud} />
        <BtnAddRecord crud={crud} />
      </Filters>
    )
    // eslint-disable-next-line
  }, [crud.filter, crud.inactive])

  return (
    <Box fontFamily={'Roboto Condensed'} h='100%'>
      <CRUD id='templates' crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Editor max={true} crud={crud}>
          <TemplateEdit id='template-edit' crud={crud} />
        </Editor>
        <Table
          id='template-table'
          crud={crud}
          columns={columns}
          responsive
          paginationContext={true}
        ></Table>
      </CRUD>
    </Box>
  )
}
