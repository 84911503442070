import React, { useEffect } from 'react'
import { Grid, InputGroup, GridItem, Checkbox } from '@chakra-ui/react'

import { Form, useForm, Input, InputControl, Select } from 'Library/CRUD'
import { getSelect } from 'Library'
import { useStore } from 'Store'

interface IRateEdit {
  id: string
  crud: any
}

const classSelect = [
  { value: 'MERCHFEE', label: 'Merchant Fee' },
  { value: 'TRANFEE', label: 'Transaction Fee' },
  { value: 'SURCHARGE', label: 'Surcharge' },
]

const basisSelect = [
  { value: 'P', label: 'Percentage' },
  { value: 'V', label: 'Value' },
]

export const RateEdit = (props: IRateEdit) => {
  let { id, crud } = props
  let form = useForm()
  let {
    basis,
    rateclass,
    rateplan,
    inactive,
    apply_to_card_type,
    apply_to_refund,
    apply_to_authorisation,
    apply_to_premium_only,
    apply_to_foreign_only,
  } = form.watch()

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record)
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record])

  let lookup = useStore((state: any) => state.lookup)
  let planSelect = getSelect(lookup.rateplan, {
    keyField: 'code',
    label: 'name',
  })
  let cardSelect = getSelect(lookup.cardtype, {
    keyField: 'code',
    label: 'name',
    selectAll: { value: '*', label: 'All' },
  })

  const onSubmit = async (e: any) => {
    // e.preventDefaullt()
    let result = await crud.submit(form.getValues())
    if (result && !result.error) crud.set({ active: false })
  }

  return (
    <Form
      form={form}
      crud={crud}
      id={id}
      minHeight={500}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input
            form={form}
            name='code'
            label='Code'
            variant='input'
            size='lg'
            readOnly={crud.key}
            placeholder='Enter Rate Code'
            sx={{ textTransform: 'uppercase' }}
            validation={{
              required: 'Code is Required',
            }}
          />
        </GridItem>
        <InputControl
          id='rateplan'
          form={form}
          label='Rate Plan'
          validation={{
            required: 'Rate Plan is Required',
          }}
          control={
            <InputGroup size='lg'>
              <Select
                name='class'
                defaultValue={rateplan}
                options={planSelect}
                onChange={(val: any) => {
                  //crud.setRecord({ class: val })
                  form.setValue('rateplan', val)
                }}
                size='lg'
                placeholder='Select Rate Class...'
              />
            </InputGroup>
          }
        />
        <GridItem />
        <GridItem colSpan={2}>
          <Input
            form={form}
            name='name'
            label='Name'
            variant='input'
            size='lg'
            placeholder='Enter Merchant Name'
            validation={{
              required: 'Merchant Name is Required',
            }}
          />
        </GridItem>
        <GridItem>
          <InputControl
            id='rateclass'
            form={form}
            label='Rate Class'
            validation={{
              required: 'Rate Class is Required',
            }}
            control={
              <InputGroup size='lg'>
                <Select
                  name='class'
                  defaultValue={rateclass}
                  options={classSelect}
                  onChange={(val: any) => {
                    //crud.setRecord({ class: val })
                    form.setValue('rateclass', val)
                  }}
                  size='lg'
                  placeholder='Select Rate Class...'
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem>
          <InputControl
            id='apply_to_card_type'
            form={form}
            label='Apply To Card Type (Specific Card will trump All)'
            control={
              <InputGroup size='lg'>
                <Select
                  name='class'
                  defaultValue={apply_to_card_type}
                  options={cardSelect}
                  onChange={(val: any) => {
                    //crud.setRecord({ class: val })
                    form.setValue('apply_to_card_type', val)
                  }}
                  size='lg'
                  placeholder='Select Card Type...'
                />
              </InputGroup>
            }
          />
        </GridItem>

        <GridItem>
          <InputControl
            id='basis'
            form={form}
            label='Charge Basis'
            validation={{
              required: 'Basis is Required',
            }}
            control={
              <InputGroup size='lg'>
                <Select
                  name='class'
                  defaultValue={basis}
                  options={basisSelect}
                  onChange={(val: any) => {
                    //crud.setRecord({ class: val })
                    form.setValue('basis', val)
                  }}
                  size='lg'
                  placeholder='Select Basis...'
                />
              </InputGroup>
            }
          />
        </GridItem>

        <GridItem>
          <Input
            form={form}
            name='value'
            label={basis === 'P' ? `Percentage (%)` : `Value ($)`}
            controlProps={{ textAlign: 'right' }}
            variant='input'
            type='number'
            size='lg'
            placeholder='Enter Value'
            validation={{
              required: 'Value is Required',
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={apply_to_refund}
            size='lg'
            onChange={(e) => {
              form.setValue('apply_to_refund', e.target.checked)
            }}
          >
            Apply To Refund
          </Checkbox>
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={apply_to_authorisation}
            size='lg'
            onChange={(e) => {
              form.setValue('apply_to_authorisation', e.target.checked)
            }}
          >
            Apply To Authorisation
          </Checkbox>
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={apply_to_foreign_only}
            size='lg'
            onChange={(e) => {
              form.setValue('apply_to_foreign_only', e.target.checked)
            }}
          >
            Apply To Foreign Cards Only
          </Checkbox>
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={apply_to_premium_only}
            size='lg'
            onChange={(e) => {
              form.setValue('apply_to_premium_only', e.target.checked)
            }}
          >
            Apply To Premium Cards Only
          </Checkbox>
        </GridItem>
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={inactive}
            size='lg'
            onChange={(e) => {
              form.setValue('inactive', e.target.checked)
            }}
          >
            Set as Inactive
          </Checkbox>
        </GridItem>
      </Grid>
    </Form>
  )
}
