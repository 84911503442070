import React, { useEffect, useState } from 'react'
import { Select } from 'chakra-react-select'
import { FormControl } from '@chakra-ui/react'
//import { _findBy } from 'lodash/findBy'

export const FilterInputSelect = (props) => {
  const { options, defaultValue, onChange, ...o } = props
  const [value, setValue] = useState(null)
  useEffect(() => {
    //Set Object Label / Value based on just value supplied as defaultValue
    let opt = options.filter((rec) => rec.value === defaultValue)
    if (opt) setValue(opt)
  }, [defaultValue, options])
  return (
    <FormControl>
      <Select
        colorScheme='purple'
        value={value}
        size='md'
        options={options}
        menuPortalTarget={document.body}
        onChange={(val) => {
          onChange(val.value, val)
        }}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 100 }),
        }}
        {...o}
      />
    </FormControl>
  )
}
