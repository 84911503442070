import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, GridItem, useMediaQuery } from '@chakra-ui/react'
import { Menu, sizing } from '.'
import { useAuth, IUseAuthType } from 'Auth'

interface Props {
  children?: JSX.Element
}

const UI: React.FC<Props> = ({ children }) => {
  const user = useAuth((state: IUseAuthType) => state.user)
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [panelOpen, setPanelOpen] = useState(true)
  const [largeDevice] = useMediaQuery('(min-width: 1024px)')

  useEffect(() => {
    //Always srat new login on dashboard
    if (user.mode === 'API')
      navigate('/paygate') //Mini Routes for Embedded access
    else navigate('/dashboard')
    // eslint-disable-next-line
  }, [])

  let panelWidth
  if (largeDevice)
    panelWidth = panelOpen
      ? sizing.lg.panelWidthOpen
      : sizing.lg.panelWidthClosed
  else
    panelWidth = panelOpen
      ? sizing.base.panelWidthOpen
      : sizing.base.panelWidthClosed

  // if (user.mode === 'API') {
  //   return <TabMenu />
  //} else {
  //RETURN UI WITH SIDEBAR MENU
  if (user.mode === 'API') {
    return (
      <Box
        h='100vh'
        w='100vw'
        m={0}
        id='paygate-app-outer-wrapper'
        overflow='hidden'
      >
        {children}
      </Box>
    )
  } else {
    return (
      <Box
        h='100vh'
        w='100vw'
        m={0}
        id='paygate-app-outer-wrapper'
        overflow='hidden'
      >
        <Grid h='100%' w='100%' templateColumns={`${panelWidth}px 1fr`}>
          <GridItem>
            <Menu />
          </GridItem>
          <GridItem
            id='paygate-app-main-panel'
            bg='gray.100'
            overflow='auto'
            p={1}
          >
            {children}
          </GridItem>
        </Grid>
      </Box>
    )
  }
}

export default UI
