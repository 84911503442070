/* eslint-disable */
import * as React from 'react'
import { IconProps } from '@chakra-ui/react'
import Icons, { IconVariant } from './Icons'
import { PseudoPropNames } from 'Types'
import { ResponsiveValue } from '@chakra-ui/styled-system'

interface Props extends Omit<IconProps, PseudoPropNames> {
  variant: IconVariant
  size?: ResponsiveValue<number>
}

const Icon: React.FC<Props> = (props) => {
  const { variant, size = 4, ...rest } = props
  const Elm = Icons[variant as unknown as string]
  // @ts-ignore
  return <Elm {...rest} boxSize={size} />
}

export default Icon
