import React from 'react'
import { Box, Flex, Spacer } from '@chakra-ui/react'

interface IconBoxProps {
  title: React.ReactNode | string
  icon: React.ReactNode
  iconProps?: any
  titleProps?: any
  children?: React.ReactNode
  [other: string]: any
}

const IconBox = (props: IconBoxProps) => {
  const { title, icon, iconProps, titleProps, children, ...rest } = props
  return (
    <Box
      border='1px lightgray solid'
      mt='30px'
      borderRadius='5px'
      bg='white'
      {...rest}
      {...props.other} //Dummy here to get around typescript issues
    >
      <Flex
        bg='blue'
        borderRadius='5px'
        mt='-20px'
        ml='20px'
        w='85px'
        h='85px'
        pos='absolute'
        p={2}
        alignItems={'center'}
        justifyContent={'center'}
        boxShadow='xl'
        {...iconProps}
      >
        <i>{icon}</i>
      </Flex>
      <Box p={3}>
        <Flex h='70px' direction='row'>
          <Spacer />
          <Box fontSize='20px' {...titleProps}>
            {title}
          </Box>
        </Flex>
        {children}
      </Box>
    </Box>
  )
}

export default IconBox
