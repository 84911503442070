import _find from 'lodash/find'
export const _clear = (obj: any, exclude = {}, defaults = {}) => {
  for (let key in obj) {
    if (!_find(exclude, key)) {
      if (typeof obj[key] === 'string') obj[key] = ''
      if (typeof obj[key] === 'number') obj[key] = 0
      if (typeof obj[key] === 'boolean') obj[key] = false
      if (typeof obj[key] === 'object') {
        if (obj[key] && obj[key].length) obj[key] = []
        else {
          if (obj[key] && obj[key].getMonth) obj[key] = new Date()
          else obj[key] = null //Changed from {}
        }
      }
    }
  }
  obj = { ...obj, ...defaults }
  return obj
}
