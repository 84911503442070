import React, { useEffect } from 'react'
import { Grid, GridItem, Checkbox } from '@chakra-ui/react'
//import { ChakraStylesConfig } from 'chakra-react-select'

import { Form, useForm, Input } from 'Library/CRUD'

interface IRateEdit {
  id: string
  crud: any
}

export const RatePlanEdit = (props: IRateEdit) => {
  let { id, crud } = props
  let form = useForm()
  let { inactive } = form.watch()

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record)
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record])

  const onSubmit = async (e: any) => {
    // e.preventDefaullt()
    let result = await crud.submit(form.getValues())
    if (result && !result.error) crud.set({ active: false })
  }

  return (
    <Form
      form={form}
      crud={crud}
      id={id}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input
            form={form}
            name='code'
            label='Code'
            variant='input'
            size='lg'
            readOnly={crud.key}
            placeholder='Enter Merchant Code'
            sx={{ textTransform: 'uppercase' }}
            validation={{
              required: 'Merchant Code is Required',
            }}
          />
        </GridItem>
        <GridItem />
        <GridItem colSpan={2}>
          <Input
            form={form}
            name='name'
            label='Name'
            variant='input'
            size='lg'
            placeholder='Enter Merchant Name'
            validation={{
              required: 'Merchant Name is Required',
            }}
          />
        </GridItem>
        <GridItem>
          <GridItem colSpan={2}>
            <Checkbox
              isChecked={inactive}
              size='lg'
              onChange={(e) => {
                form.setValue('inactive', e.target.checked)
              }}
            >
              Set as Inactive
            </Checkbox>
          </GridItem>
        </GridItem>
      </Grid>
    </Form>
  )
}
