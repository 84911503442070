import React from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import {
  Box,
  Grid,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { Header, TaskCard } from 'Library/Chunks'
import { useAuth, IUser, IUseAuthType } from 'Auth'

export const Reports = (props: any) => {
  const user: IUser = useAuth((state: IUseAuthType) => state.user)
  //const [activeTab, setActiveTab] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  let parts = location.pathname.split('/')
  let activeTab
  switch (parts[2]) {
    case 'setup':
      activeTab = 1
      break
    case 'logging':
      activeTab = 2
      break
    default:
      activeTab = 0
  }
  let subItem = parts && parts[1] && parts[1] === 'management' && parts[3]

  //IF THERE ARE SUB ITEMS THEN RETURN VIA OUTLET OTHERWISE RETURN SUB MENU
  if (subItem) return <Outlet />
  else
    return (
      <Box>
        <Header headerText='Reports' breadcrumbs={user.mode !== 'API'} />
        <Tabs defaultIndex={activeTab}>
          <TabList>
            <Tab>Management</Tab>
            <Tab>Merchant</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns='1fr 1fr 1fr' gap='22px'>
                  <TaskCard
                    index={0}
                    title='Revenue Report'
                    description='Monthly Revenue Report - to be defined'
                    pinned={false}
                    onClick={() => {
                      navigate('/reports/')
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns='1fr 1fr 1fr' gap='22px'>
                  <TaskCard
                    index={0}
                    title='Merchants Report'
                    description='To be defined'
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/merchants')
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    )
}
