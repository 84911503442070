import React from 'react'
//import { useLocation } from 'react-router-dom'
import { Card, Icon } from 'Library/Chunks'

interface ITaskCard {
  index: number
  title: string
  description: string
  pinned: boolean
  counter?: BigInteger
  hasCaret?: boolean
  hasPin?: boolean
  minHeight?: number | string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onLeftButtonClick?: (index: number, e?: React.MouseEvent<HTMLElement>) => void
  route?: string
  children?: React.ReactNode
  [other: string]: any
}

export const TaskCard: React.FC<ITaskCard> = (props) => {
  const {
    index,
    title,
    description,
    route,
    pinned,
    counter,
    onLeftButtonClick,
    minHeight,
    children,
    hasPin = true,
    hasCaret = true,
    onClick,
    ...other
  } = props
  //const location = useLocation()
  return (
    <Card
      isBordered
      isActive={true}
      _hover={{
        cursor: 'pointer',
      }}
      textDecoration='none'
      size='sm'
      colorScheme={counter ? 'yellow' : 'white'}
      minHeight={minHeight}
      height='min-content'
      maxHeight='min-content'
      title={title}
      description={description}
      leftButtonIcon={
        hasPin ? (
          <Icon variant='Pin' color={pinned ? 'orange.500' : 'gray.600'} />
        ) : (
          <></>
        )
      }
      rightButtonIcon={hasCaret ? <Icon variant='CaretRight' /> : <></>}
      boxShadow='0 0 0 1px #E6E7E8'
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        if (onClick) onClick(e)
        // if (route) history.push(route)
        // else if (onClick) onClick(e)
        //handleClickPin(id);
      }}
      onLeftButtonClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (props && props.onLeftButtonClick) props.onLeftButtonClick(index)
      }}
      {...other}
    >
      {children}
    </Card>
  )
}
