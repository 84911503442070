/* auth.tsx - Application Security Wrapper */
import React, { useEffect, useState } from 'react'
import Login from './Login'
import ApiLogin from './ApiLogin'
import { useDB } from 'Library/DB'

interface Props {
  children?: JSX.Element | JSX.Element[] | undefined
  auth?: any
  apiLogin?: boolean
}

const Auth: React.FC<Props> = ({ auth, apiLogin, children }) => {
  const [token, setToken] = useState(auth.token)
  let db = useDB()

  useEffect(() => {
    setToken(auth.user.token)
    db.setToken(auth.user.token)
    auth.setLogout(false)
    // eslint-disable-next-line
  }, [auth.user.token])

  useEffect(() => {
    if (auth.logout) {
      auth.setUser({})
      setToken(false)
    }
  }, [auth])

  //ALLOW LOGOUT VIA DB (IN CASE OF 403 ERRORS)
  useEffect(() => {
    if (db.logout) {
      auth.setUser({})
      setToken(false)
    }
    // eslint-disable-next-line
  }, [db.logout])

  //If there is user Token then load standard UI
  if (token) return <>{children}</>
  //FOR API LOGIN (VIA EMBEDED CLIENT) RETURN VIA THIS ROUTE (OTHERWISE PRESENT LOGIN SCREEN)

  if (apiLogin) return <ApiLogin auth={auth} children={children} />
  else return <Login auth={auth} />
}

export default React.memo(Auth)
