import React, { useEffect, useMemo } from 'react'
import numeral from 'numeral'
import moment from 'moment'
import { Box, GridItem, Avatar, Flex, Text, Button } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Alert,
  useAlert,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  FilterInputSelect,
  Filters,
  BtnRefresh,
  MultiLineHeader,
  NumberFormat,
} from 'Library/CRUD'
import {
  FaCheck,
  FaFlag,
  FaHourglassStart,
  FaQuestion,
  FaPiggyBank,
} from 'react-icons/fa'
import _get from 'lodash/get'

import { useDB, IUseDBType } from 'Library/DB'
import { useStore } from 'Store'
import { getSelect, getNumber } from 'Library'
import TransactionDrilldown from './TransactionDrilldown'
import { useAuth, IUser, IUseAuthType } from 'Auth'

const getStatus = (mode: string) => {
  let icon = <></>
  switch (mode) {
    case 'SETTLED':
      icon = <FaPiggyBank color='green' />
      break
    case 'VERIFIED':
      icon = <FaCheck color='green' />
      break
    case 'AUTHORISED':
      icon = <FaHourglassStart color='orange' />
      break
    case 'FAILED':
      icon = <FaFlag color='orange' />
      break
    case 'ERROR':
      icon = <FaFlag color='red' />
      break
    default:
      icon = <FaQuestion color='purple' />
  }
  return (
    <Flex direction='row' alignContent={'center'} alignItems={'center'}>
      {icon}
      <Text pl={2}>{mode}</Text>
    </Flex>
  )
}

const Variance = (props: any) => {
  let row: any = props.row
  //let settleFees = getNumber(row.settle_fees)
  let variance = 0
  if (getNumber(row.expected_fees) !== 0) {
    variance =
      getNumber(row.expected_fees - getNumber(row.settle_fees)) /
      getNumber(row.expected_fees)
  }

  let textProps: any = {}
  if (variance >= 0) textProps.color = 'green'
  else if (variance > -0.1) textProps.color = 'orange'
  else {
    textProps.color = 'red'
    textProps.fontWeight = 'bold'
  }

  return (
    <Text {...textProps}>
      {/* {numeral(variance / row.settle_fees).format('%0.00')} */}
      {variance ? numeral(variance).format('%0.00') : <></>}
    </Text>
  )
}

interface ITransactions {
  options?: any
}

const Transactions = (props: ITransactions) => {
  let options = _get(props, 'options.transactionFilter', {})

  const db = useDB()
  const user: IUser = useAuth((state: IUseAuthType) => state.user)
  let multiMerchant = user.type === 'ADMIN' ? true : false

  const [alert, setAlert] = useAlert()

  let lookup = useStore((state: any) => state.lookup)
  let merchantSelect = getSelect(lookup.merchant, {
    keyField: 'code',
    label: 'code',
    selectAll: { value: '', label: 'All' },
  })

  const crud = useCRUD({
    id: 'transaction',
    title: user.mode === 'API' ? '' : 'Transactions',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    //waitFilter: true,
    //waitRefresh: true,
    filter: {
      startDate: moment().subtract(14, 'days').toDate(),
      endDate: moment().toDate(),
      type: '',
      merchant: '',
      ...options,
    },
    fetch: async (qry: string) => {
      let result: IUseDBType = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/transaction/fetch${qry || ''}`,
      })
      return result.data
    },
  })
  const { filter, updateFilter, refresh } = crud

  const applyFees = React.useCallback(
    async (id: string) => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'POST',
        url: `/api/management/transaction/fee`,
        data: {
          id: id,
        },
      })
      crud.refresh({ message: 'Transaction Apply Fees' })
      return result
    },
    [crud, db]
  )

  //FILTER ON CHANGE OF USER MERCHANT CODE (IF LOGIN USER CHANGES)
  useEffect(() => {
    if (user.merchant) {
      updateFilter({ merchant: user.merchant })
    }
  }, [updateFilter, filter, user.merchant])

  //REFRESH CRUD IF THERE IS A CHANGE IN DB TOKEN (CAN HAPPEN WITH EMBEDDED CLIENT)
  useEffect(() => {
    if (user.mode === 'API') refresh({ message: 'Transaction Token' })
  }, [refresh, db.token, user.mode])

  let columns = useMemo(
    () => [
      // {
      //   name: 'ID',
      //   selector: (row: any) => row['id'],
      //   width: '235px',
      //   sortable: true,
      // },
      {
        name: 'Date',
        selector: (row: any) => row['date'],
        format: (row: any) => moment(row.date).format('DD/MM/YYYY@HH:MM'),
        width: '80px',
        sortable: true,
      },
      {
        name: 'Merchant',
        selector: (row: any) => row['merchant_id'],
        sortable: true,
        width: '85px',
      },
      {
        name: 'Transaction Ref',
        selector: (row: any) => row['ref'],
        sortable: true,
      },
      // {
      //   name: <MultiLineHeader heading={['Delivery', 'Date']} align='left' />,
      //   selector: (row: any) => row['checkin_date'],
      //   sortable: false,
      //   format: (row: any) => moment(row.checkin_date).format('DD/MM/YYYY'),
      //   width: '80px',
      //   right: true,
      // },
      {
        name: 'Type',
        selector: (row: any) => row['type'],
        format: (row: any) =>
          row.type === 'REFUND' ? (
            <Text color='red'>{row.type}</Text>
          ) : (
            <Text>{row.type}</Text>
          ),
        sortable: true,
        width: '65px',
      },
      {
        name: 'Status',
        selector: (row: any) => row['status'],
        format: (row: any) => getStatus(row.status),
        sortable: true,
        width: '88px',
      },
      {
        name: 'Fail',
        selector: (row: any) => row['fail_count'],
        format: (row: any) =>
          row.fail_count > 0 ? (
            <Avatar
              name={row.fail_count}
              bg='#ffcce1'
              fontWeight={800}
              size='xs'
            />
          ) : (
            <></>
          ),
        sortable: false,
        width: '45px',
        center: true,
      },

      {
        name: 'Pend',
        selector: (row: any) => row['pending_count'],
        format: (row: any) =>
          row.pending_count > 0 ? (
            <Avatar
              name={row.pending_count}
              fontWeight={800}
              bg='#e0d7ff'
              size='xs'
            />
          ) : (
            <></>
          ),
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        name: 'Error',
        selector: (row: any) => row['error_count'],
        format: (row: any) =>
          row.error_count > 0 ? (
            <Avatar
              name={row.error_count}
              bg='red'
              fontWeight={800}
              size='xs'
            />
          ) : (
            <></>
          ),
        sortable: false,
        width: '45px',
        center: true,
      },
      {
        name: 'Card Holder',
        selector: (row: any) => row['cardholder'],
        sortable: true,
        width: '120px',
      },
      {
        name: 'Mask',
        selector: (row: any) => row['card_mask'],
        format: (row: any) => row.card_mask.slice(row.card_mask.length - 4),
        sortable: false,
        width: '45px',
      },
      {
        name: (
          <MultiLineHeader heading={['Original', 'Amount']} align='right' />
        ),
        selector: (row: any) => row['original_amount'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.original_amount} />,
        width: '80px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Expeced', 'Fees']} align='right' />,
        selector: (row: any) => row['expected_fees'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.expected_fees} />,
        width: '80px',
        right: true,
        omit: user.mode === 'API' || user.type !== 'ADMIN',
      },
      {
        name: <MultiLineHeader heading={['Settle', 'Fees']} align='right' />,
        selector: (row: any) => row['fees'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.settle_fees} />,
        width: '80px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Variance', '%']} align='right' />,
        selector: (row: any) => row['fees'],
        sortable: false,
        format: (row: any) => <Variance row={row} />,
        width: '80px',
        right: true,
        omit: user.mode === 'API' || user.type !== 'ADMIN',
      },
      {
        name: <MultiLineHeader heading={['Net', 'Value']} align='right' />,
        selector: (row: any) => row['net'],
        sortable: false,
        format: (row: any) => (
          <NumberFormat amount={row.net - row.settle_fees} />
        ),
        width: '80px',
        right: true,
      },

      {
        name: 'Fees',
        cell: (row: any) => (
          <Button
            onClick={() => applyFees(row.id)}
            variant='outline'
            colorScheme='primary'
            size='xs'
          >
            Re-Apply
          </Button>
        ),
        width: '75px',
        center: true,
      },
    ],
    [applyFees, user.mode, user.type]
  )

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          `300px 220px ${multiMerchant ? '180px' : ''} 180px 180px 1fr 150px`,
        ]}
      >
        <FilterInputSearch crud={crud} />
        <FilterInputDateRange
          start={moment(filter.startDate).toDate()}
          end={moment(filter.endDate).toDate()}
          onChange={(dates: any) => {
            updateFilter({
              startDate: moment(dates[0]).toDate() || new Date(),
              endDate: moment(dates[1]).toDate() || new Date(),
            })
          }}
        />
        {multiMerchant && (
          <FilterInputSelect
            placeholder='Merchant...'
            defaultValue={filter.merchant || ''}
            width='150px'
            onChange={(val: string) => {
              updateFilter({ merchant: val })
            }}
            options={merchantSelect}
          />
        )}
        <FilterInputSelect
          placeholder='Select Type...'
          defaultValue={filter.type || ''}
          width='150px'
          onChange={(val: string) => {
            updateFilter({ type: val })
          }}
          options={[
            {
              label: 'All',
              value: '',
            },
            {
              label: 'Payment',
              value: 'PAYMENT',
            },
            {
              label: 'Pre-Auth',
              value: 'PREAUTH',
            },
            {
              label: 'Refund',
              value: 'REFUND',
            },
            {
              label: 'Claim',
              value: 'CLAIM',
            },
          ]}
        />
        <FilterInputSelect
          placeholder='Select ...'
          defaultValue={filter.mode || ''}
          width='150px'
          onChange={(val: string) => {
            updateFilter({ mode: val })
          }}
          options={[
            {
              label: 'All',
              value: '',
            },
            {
              label: 'Failed',
              value: 'is_failed',
            },
            {
              label: 'Has Errors',
              value: 'is_error',
            },
            {
              label: 'Still Pending',
              value: 'is_pending',
            },
            {
              label: 'Completed',
              value: 'is_complete',
            },
          ]}
        />
        <GridItem />
        <BtnRefresh crud={crud} cascade={true} />
      </Filters>
    )
  }, [crud, filter, merchantSelect, multiMerchant, updateFilter])

  const conditionalRowStyles = [
    {
      when: (row: any) => row.type === 'PAYMENT' && row.settled > 0,
      style: {
        color: 'green',
        backgroundColor: '#f3f8f3',
        fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.type === 'PREAUTH' && row.net > 0,
      style: {
        color: 'purple',
        backgroundColor: '#f6f1f6',
        fontWeight: 600,
      },
    },
  ]

  return (
    <Box fontFamily={'Roboto Condensed'} h='100%'>
      <CRUD id='transactions' crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <Table
          id='bank_table'
          crud={crud}
          columns={columns}
          responsive
          expandableRows
          //expandableRowDisabled={(row: any) => row.count <= 1}
          expandableRowsComponent={TransactionDrilldown}
          expandableRowsComponentProps={{
            pl: '45px',
            filter: crud.filter,
            refreshParent: crud.refresh, //Allows Refresh of this parent to be called by child
          }}
          conditionalRowStyles={conditionalRowStyles}
          striped={false}
        ></Table>
      </CRUD>
    </Box>
  )
}

export default React.memo(Transactions)
