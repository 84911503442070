/*******************************************
 *** Input() - Input for Crud Operations ***
 *******************************************
 This is a simplified input that wraps up the InputControler with different variants of inputs
 variants:
   input    - Standard Chakra Input
   select   - Customised Select based on React-Select
   checkbox - Checkbox based on Chakra Checkbox
 */

import React, { useEffect, useState } from 'react'
import { Input as ChakraInput, InputGroup } from '@chakra-ui/react'
import { InputControl } from 'Library/CRUD'

export const Input = (props) => {
  let {
    form,
    name,
    label,
    size,
    variant,
    validation,
    outerProps,
    controlProps,
    readOnly,
    placeholder,
    value,
    sx,
    control,
    ...rest
  } = props

  const [controlElement, setControlElement] = useState(<></>)

  useEffect(() => {
    switch (variant) {
      case 'custom':
        setControlElement(control)
        break
      case 'input':
      default:
        setControlElement(
          <InputGroup size='lg'>
            <ChakraInput
              name={name}
              sx={sx}
              defaultValue={value}
              onChange={(e) => form.setValue(name, e.target.value)}
              size={size || 'lg'}
              placeholder={placeholder}
              {...controlProps}
              {...rest}
            />
          </InputGroup>
        )
    }
    // eslint-disable-next-line
  }, [variant])

  return (
    <InputControl
      id={name}
      form={form}
      label={label}
      validation={validation}
      control={controlElement}
      readOnly={readOnly}
      {...outerProps}
      bg='gray.100'
    />
  )
}
