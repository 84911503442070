import React from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import {
  Box,
  Grid,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { Header, TaskCard } from 'Library/Chunks'

export const Management = (props: any) => {
  //const [activeTab, setActiveTab] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  let parts = location.pathname.split('/')
  let activeTab
  switch (parts[2]) {
    case 'setup':
      activeTab = 1
      break
    case 'logging':
      activeTab = 2
      break
    default:
      activeTab = 0
  }
  let subItem = parts && parts[1] && parts[1] === 'management' && parts[3]

  //IF THERE ARE SUB ITEMS THEN RETURN VIA OUTLET OTHERWISE RETURN SUB MENU
  if (subItem) return <Outlet />
  else
    return (
      <Box>
        <Header headerText='Management' />
        <Tabs defaultIndex={activeTab}>
          <TabList>
            <Tab>Review</Tab>
            <Tab>Setup</Tab>
            <Tab>Logging</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns='1fr 1fr 1fr' gap='22px'>
                  <TaskCard
                    index={0}
                    title='Notifications'
                    isActive={false}
                    hasPin={false}
                    description='View Provider Notifications and re-submit unactioned notifications'
                    pinned={false}
                    onClick={() => {
                      navigate('/management/review/notifications')
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns='1fr 1fr 1fr' gap='22px'>
                  <TaskCard
                    index={0}
                    title='Merchants'
                    isActive={false}
                    description='View, Create and Edit Merchants Accounts'
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/merchants')
                    }}
                  />

                  <TaskCard
                    index={0}
                    title='Provider Templates'
                    description='View, Create and Edit Provider Templates'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/templates')
                    }}
                  />
                  <TaskCard
                    index={0}
                    title='Rate Plans'
                    description='View, Create  and Edit Rate Plans'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/rateplans')
                    }}
                  />
                  <TaskCard
                    index={0}
                    title='Rates'
                    description='View, Create  and Edit Rates'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/rates')
                    }}
                  />
                  <TaskCard
                    index={0}
                    title='Users'
                    description='View, Create and Edit User Logins'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/setup/users')
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box p={3}>
                <Grid templateColumns='1fr 1fr 1fr' gap='22px'>
                  <TaskCard
                    index={0}
                    title='Outbound Request Logs'
                    description='View Outbound Provider Requests'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/logging/outbound')
                    }}
                  />
                  <TaskCard
                    index={0}
                    title='Request Logs'
                    description='View Payment Gateway Request Logs'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/logging/requests')
                    }}
                  />
                  <TaskCard
                    index={0}
                    title='Error Logs'
                    description='View Payment Gateway Error Logs'
                    isActive={false}
                    pinned={false}
                    onClick={() => {
                      navigate('/management/logging/errors')
                    }}
                  />
                </Grid>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    )
}
