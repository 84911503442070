import { useState } from 'react'

export const useAlert = (args) => {
  let options = {
    active: false,
    modal: false,
    title: '',
    message: '',
    color: 'warning',
    icon: 'info-circle', //Note: When called can have prefix far:

    ...args,
  }
  const [state, setState] = useState(options)
  const setAlert = (arg, timeout = null) => {
    if (typeof arg === 'boolean') {
      if (arg === true) setState({ ...state, active: true })
      else setState({ ...state, active: false, exclusive: false })
    } else if (typeof arg === 'string') {
      setState({ ...state, message: arg, active: true })
    } else {
      setState({ ...state, ...arg })
    }
    if (timeout) setTimeout(() => setState(options), timeout)
  }
  const clearAlert = (arg, timeout = null) => {
    setState({ ...state, active: false, modal: false, title: '', message: '' })
  }
  return [{ ...state }, setAlert, clearAlert]
}
