import create from 'zustand'
import { persist } from 'zustand/middleware'
import { sizing } from 'UI'

interface IUseStoreState {
  panelOpen: boolean
  largeDevice: boolean
  panelWidth: number
  lookup: {
    merchant: []
    provider: []
    rateplan: []
    cardtype: []
  }
  setPanelOpen: (flag: boolean) => void
  setLargeDevice: (flag: boolean) => void
  setPanelWidth: (width: number) => void
  // eslint-disable-next-line no-empty-pattern
  setLookup?: (id: string, {}: any) => void
}

export const useStore = create<IUseStoreState | any>(
  persist(
    (set, get) => ({
      panelOpen: true, //SidePanel Open or Closed
      largeDevice: true, //Set When device is of size large or above
      panelWidth: sizing.lg.panelWidthOpen,
      lookup: {
        merchant: [],
        provider: [],
        rateplan: [],
        cardtype: [],
      },
      setPanelOpen: (flag: boolean) => set({ panelOpen: flag }),
      setLargeDevice: (flag: boolean) => set({ largeDevice: flag }),
      setPanelWidth: (width: string | number) => set({ panelWidth: width }),
      setLookup: (id: string, arr: []) => {
        set((state: any) => ({ lookup: { ...state.lookup, [id]: arr } }))
      },
    }),
    {
      name: 'paygate-store',
      getStorage: () => sessionStorage,
    }
  )
)
