import create from 'zustand'
import { devtools } from 'zustand/middleware'
import nativeAxios from 'axios'
import _get from 'lodash/get'

export interface IUseDBType {
  error?: boolean
  status?: number
  message?: string
  data?: object
  setToken?: (token: string) => void
}

export interface IUseDBAlert {
  status: number
  message: string
  identity: string
  title: string
  details?: any
}

export interface IUseDB {
  loading: boolean
  headers: object
  request: object
  logout: boolean
  status: number
  alert?: IUseDBAlert
  token?: string
  mode?: string
  setToken: (token: string) => void
  setLoading: (token: boolean) => void
  axios: (obj: object) => any
  setLogout: (flag: boolean) => void
}

export const axios = async (arg: any, opts: any): Promise<IUseDBType> => {
  let result
  const { set, get } = opts
  let state = get()

  let token = String(state.token).trim()
  let mode = token.length > 40 ? 'LOGIN' : 'API'

  try {
    let server =
      arg && arg.server === 'VAULT'
        ? process.env.REACT_APP_VAULT_SERVER
        : process.env.REACT_APP_PAYGATE_SERVER
    set({ loading: true })
    let args = {
      method: 'GET',
      data: {},
      ...arg,
    }
    if (args.server === 'VAULT') {
      args.headers = {
        'X-API-KEY': process.env.REACT_APP_VAULT_APIKEY,
      }
    } else if (args.server === 'PAYGATE_API') {
      //PayGate Via API KEY (Used for Widget Demo)
      args.headers = { 'x-api-key': process.env.REACT_APP_PAYGATE_APIKEY }
    } else {
      //Paygate Use Login
      if (mode === 'API') args.headers = { 'x-api-key': token }
      else args.headers = { 'x-user-token': token }
    }
    args.url = server + arg.url
    result = await nativeAxios(args)

    //Set db Values after call
    set({ headers: result.headers })
    set({ request: result.request })
    set({ status: result.status })
    set({ loading: false })

    return {
      error: result.data.error || result.status !== 200,
      status: result.status,
      message: result.data.message || '',
      data: result.data || [],
    }
    //return retObj
  } catch (e: any) {
    let status = _get(e, 'response.status', 418)
    // /&& mode === 'API'
    if (status === 403) {
      //IF API MODE AND FORBIDDEN ERROR THEN RELOAD WITH ?api PARAMATER
      state.setLogout(true)
      if (mode === 'API') window.location.assign(window.location + '?api')
    }
    let errObj = {
      error: true,
      message: _get(
        e,
        'response.data.message',
        _get(
          e,
          'response.message',
          status === 404
            ? 'Invalid API Route ' + arg.method + ':' + arg.url
            : 'Unknown Error'
        )
      ),
      status: status,
      data: _get(e, 'response.data', []),
    }
    set({ loading: false })

    console.error('@@ db.axios Error', errObj)
    return errObj
  }
}

//CREATE STORE WITH DEVTOOLS REDUX DEBUGGING
export const useDB = create<IUseDB | any>(
  devtools(
    (set, get) => ({
      status: 0,
      token: '',
      headers: {},
      request: {},
      logout: false,
      loading: false,
      alert: {
        status: 0,
        message: '',
        identity: '',
        title: '',
      },
      setLoading: (flag: boolean) => set({ loading: flag }),
      setAlert: (obj: IUseDBAlert) => set({ alert: obj }),
      setToken: (token: string) => set({ token: token }),
      setLogout: (flag: boolean) => set({ logout: flag }),
      clearAlert: () =>
        set({ alert: { status: 0, message: '', identity: '', title: '' } }),
      axios: async (obj: object) => {
        let result: any = await axios(obj, { set, get })
        //SHOW ALERT FOR ANY ERRORS
        if (result && result.error) set({ alert: result })
        return result
      },
    }),
    {
      name: 'paygate-db',
    }
  )
)
