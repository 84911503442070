import React from 'react'
import { Box, Grid, Flex, Text, GridItem } from '@chakra-ui/react'
import { Header, IconBox } from 'Library'
import { FaAsterisk, FaChartLine, FaChartBar } from 'react-icons/fa'
import { useAuth, IUseAuthType } from 'Auth'
export const Dashboard = () => {
  const user = useAuth((state: IUseAuthType) => state.user)
  return (
    <Box>
      {user.mode !== 'API' && <Header headerText='Dashboard' />}
      <Grid
        p={2}
        gap='8px'
        templateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
      >
        <GridItem>
          <IconBox
            title={<Text fontWeight='600'>Gateway Issues</Text>}
            iconProps={{ bg: 'red' }}
            icon={<FaAsterisk size='50px' color='white' />}
          >
            <Flex
              direction='column'
              h='450px'
              w='100%'
              bg='gray.100'
              boxShadow='5px'
              alignItems='center'
              justifyContent='center'
            >
              ...Coming Soon
            </Flex>
          </IconBox>
        </GridItem>
        <GridItem>
          <IconBox
            title={<Text fontWeight='600'>Revenue Chart</Text>}
            iconProps={{ bg: 'green' }}
            icon={<FaChartLine size='50px' color='white' />}
          >
            {' '}
            <Flex
              direction='column'
              h='450px'
              w='100%'
              bg='gray.100'
              boxShadow='5px'
              alignItems='center'
              justifyContent='center'
            >
              ...Coming Soon
            </Flex>
          </IconBox>
        </GridItem>
        <GridItem>
          <IconBox
            title={<Text fontWeight='600'>Performance Rankings</Text>}
            icon={<FaChartBar size='50px' color='white' />}
          >
            <Flex
              direction='column'
              h='450px'
              w='100%'
              bg='gray.100'
              boxShadow='5px'
              alignItems='center'
              justifyContent='center'
            >
              ...Coming Soon
            </Flex>
          </IconBox>
        </GridItem>
      </Grid>
    </Box>
  )
}
