import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useDB } from 'Library/DB'
import { IUseAuthType } from 'Auth'
import _get from 'lodash/get'
import { Logo } from 'Assets'

import { EnumMode } from './useAuth'

interface LoginProps {
  children: JSX.Element | JSX.Element[] | undefined
  auth?: IUseAuthType
}

const ApiLogin: React.FC<LoginProps> = ({ auth, children }) => {
  // eslint-disable-next-line

  //Use Local Storage for Heights as it will be lost if db.token is lost (eg: For demo app)
  const [height, setHeight] = useState(
    localStorage.getItem('paygate-client-window-height') || 500
  )

  const db = useDB()
  // const navigate = useNavigate()

  useLayoutEffect(() => {
    window.parent.postMessage(
      {
        message: 'READY',
        ready: true,
      },
      '*'
    )
  }, [])

  useEffect(() => {
    const receiveMessage = (event: any) => {
      if (event && event.data) {
        let obj = event.data
        let session = _get(obj, 'session', false)
        let variant = _get(obj, 'variant', '')
        let h = _get(obj, 'height', 0)
        if (h) {
          setHeight(h)
        }
        if (variant) {
          console.log('PayGate-Client Vairant', variant)
          //  navigate(`/paygate/${String(variant).toLowerCase()}`)
          auth?.setVariant(variant)
        }
        if (session && session.token) {
          let userObj = {
            id: session.merchant,
            name: session.merchant,
            token: session.token,
            mode: EnumMode.API,
            merchant: session.merchant,
          }
          auth?.setUser(userObj)
          db.setToken(session.token)
        } else if (session && !session.token) {
          db.setToken(null)
          auth?.setUser({ id: '', name: '', token: '', mode: EnumMode.API })
        }
      }
      return () => {
        window.removeEventListener('message', receiveMessage)
      }
    }
    //Add Event Listener for Iframe on Init
    window.addEventListener('message', receiveMessage, false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem('paygate-client-window-height', String(height))
  }, [height])

  if (db.token) {
    return (
      <Box p={2} w='100%' height={height}>
        {children}
      </Box>
    )
  } else {
    //Display Waiting Spinner when waiting for session token
    return (
      <Flex
        id='paygate-client-loading'
        height={height}
        direction='row'
        p={2}
        alignItems={'center'}
        justifyContent='center'
      >
        <Flex direction='column' alignItems={'center'} justifyContent='center'>
          <Logo color='#1497E8' width={250} />
          <Text
            fontSize={18}
            fontWeight={500}
            color='blue.600'
            mt={'-20px'}
            ml='80px'
          >
            Payment Gateway
          </Text>
          <Text
            mt={2}
            w={400}
            ml={1}
            fontSize={14}
            as='i'
            color='gray.400'
            align='center'
          >
            To activate the Payment Gateway please contact Guestpoint Support on
            1300 110 449 (Australia) or (+617) 3063 3701 or email
            sales@guestpoint.com
          </Text>
        </Flex>
      </Flex>
    )
  }
}

export default ApiLogin
