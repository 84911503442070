import React, { useEffect, useMemo } from 'react'
import _sumBy from 'lodash/sumBy'

import { Box, Text } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Alert,
  useAlert,
  Table,
  NumberFormat,
} from 'Library/CRUD'

import { useDB } from 'Library/DB'
import { useAuth, IUser, IUseAuthType } from 'Auth'

interface IPendingSettlementProps {
  amount: number
  setAmount: (n: number) => void
}

const PendingSettlement = (props: IPendingSettlementProps) => {
  const { setAmount } = props
  const db = useDB()
  const user: IUser = useAuth((state: IUseAuthType) => state.user)

  const [alert, setAlert] = useAlert()

  const crud = useCRUD({
    id: 'transaction',
    title: user.mode === 'API' ? '' : 'Transactions',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    waitRefresh: true,
    filter: {
      merchant: user.merchant,
    },
    fetch: async (qry: string) => {
      // if (!filter.query) return [] //Wait until there is a filter
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/dashboard/pending${qry}`,
      })
      let total = _sumBy(result.data, (rec: any) => parseFloat(rec.amount))
      setAmount(total)
      return result.data
    },
  })

  useEffect(() => {
    crud.refresh({
      message: 'CHANGE USER ID',
      filter: { merchant: user.merchant },
    })
    // eslint-disable-next-line
  }, [user.merchant])

  let columns = useMemo(
    () => [
      {
        name: 'Card Type',
        selector: (row: any) => row['card_name'],
        sortable: true,
        //width: '85px',
      },
      {
        name: 'Type',
        selector: (row: any) => row['type'],
        format: (row: any) =>
          row.type === 'REFUND' ? (
            <Text color='red'>{row.type}</Text>
          ) : (
            <Text>{row.type}</Text>
          ),
        sortable: true,
        width: '60px',
      },
      {
        name: 'Amount',
        selector: (row: any) => row['amount'],
        sortable: false,
        format: (row: any) => <NumberFormat amount={row.amount} />,
        right: true,
        width: '90px',
      },
    ],
    // Override ignore functions
    // eslint-disable-next-line
    []
  )

  return (
    <Box fontFamily={'Roboto Condensed'} h='100%' overflow={'hidden'}>
      <CRUD id='transactions' crud={crud}>
        <Alert alert={alert} setAlert={setAlert} />
        <Table
          pagination={false}
          id='bank_table'
          crud={crud}
          columns={columns}
          responsive={true}
          striped={false}
        ></Table>
      </CRUD>
    </Box>
  )
}

export default React.memo(PendingSettlement)
