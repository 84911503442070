/***************************************************
 *** FilterInputRange() - Crud Date Range Filter ***
 ***************************************************
 2022-07-05 Fix problem when input date was changing when reactivating search
 */
import React, { useState, useMemo } from 'react'
import {
  Input,
  FormControl,
  InputGroup,
  InputRightElement,
  useTheme,
  css as chakraCSS,
} from '@chakra-ui/react'
import { CalendarIcon } from '@chakra-ui/icons'
import DatePicker from 'react-datepicker'
import { ClassNames } from '@emotion/react'

function useDatePickerStyles() {
  const theme = useTheme()
  return useMemo(() => {
    const defaultStyles = {
      '& .react-datepicker': {
        '&__header': {
          bg: 'gray.100',
        },
        '&__day': {
          lineHeight: '28px',
          w: 7,
          h: 7,
          borderRadius: 'full',
        },
      },
    }
    return chakraCSS(defaultStyles)(theme)
  }, [theme])
}

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <InputGroup>
      <Input size='md' {...props} ref={ref} />
      <InputRightElement
        userSelect='none'
        pointerEvents='none'
        children={<CalendarIcon />}
      />
    </InputGroup>
  )
})

export const FilterInputDateRange = (props) => {
  const { start, end } = props
  const [startDate, setStartDate] = useState(start || new Date())
  const [endDate, setEndDate] = useState(end || new Date())
  let styles = useDatePickerStyles()
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    if (props && props.onChange) {
      if (start && end) props.onChange(dates)
    }
  }
  return (
    <FormControl>
      <ClassNames>
        {({ css }) => (
          <DatePicker
            dateFormat='dd/MM/yyyy'
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            portalId='filter-range-portal'
            customInput={<CustomInput />}
            calendarClassName={css(styles)}
          />
        )}
      </ClassNames>
    </FormControl>
  )
}
