import React, { useEffect } from 'react'
import {
  Grid,
  Input as ChakraInput,
  InputGroup,
  GridItem,
  Checkbox,
  Alert,
} from '@chakra-ui/react'
//import { ChakraStylesConfig } from 'chakra-react-select'

import { Form, useForm, Input, InputControl, Select } from 'Library/CRUD'
import { getSelect } from 'Library'
import { useStore } from 'Store'

interface IMerchantEdit {
  id: string
  crud: any
}

export const UserEdit = (props: IMerchantEdit) => {
  let { id, crud } = props
  let form = useForm()
  let { merchant_id, token_expiry_seconds, inactive } = form.watch()

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record)
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record])

  let lookup = useStore((state: any) => state.lookup)
  let merchantSelect = getSelect(lookup.merchant, {
    keyField: 'id',
    label: 'name',
  })

  const onSubmit = async (e: any) => {
    // e.preventDefaullt()
    let result = await crud.submit(form.getValues())
    if (result && !result.error) crud.set({ active: false })
  }

  return (
    <Form
      minHeight={550}
      form={form}
      crud={crud}
      id={id}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      {crud && !crud.key && (
        <Alert as='i' mt={2} borderRadius='5px' status='warning'>
          Note: When users are created, an email with be sent to them to allow
          assignment of password
        </Alert>
      )}
      <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
        <GridItem>
          <Input
            form={form}
            name='id'
            label='Code'
            variant='input'
            size='lg'
            readOnly={crud.key}
            placeholder='User ID'
            sx={{ textTransform: 'uppercase' }}
            validation={{
              required: 'Merchant ID is Required',
            }}
          />
        </GridItem>
        <GridItem />
        <GridItem colSpan={2}>
          <Input
            form={form}
            name='name'
            label='Name'
            variant='input'
            size='lg'
            placeholder='User Name'
            validation={{
              required: 'User Name is Required',
            }}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Input
            form={form}
            name='email'
            label='email'
            variant='input'
            type='email'
            size='lg'
            placeholder='Email Address'
            validation={{
              required: 'Email address is Required',
            }}
          />
        </GridItem>
        <GridItem>
          <InputControl
            id='merchant_id'
            form={form}
            label='Merchant'
            validation={{
              required: 'Merchant is Required',
            }}
            control={
              <InputGroup size='lg'>
                <Select
                  portal={true}
                  name='merchant_id'
                  defaultValue={merchant_id}
                  options={merchantSelect}
                  onChange={(val: any) => {
                    form.setValue('merchant_id', val)
                  }}
                  size='lg'
                  placeholder='Select Merchant...'
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem>
          {/* NOTE - THIS LEFT HERE AS EXAMPLE OF NOT USING SIMPIFIED INPUT CONTROL */}
          <InputControl
            id='token_expiry_seconds'
            form={form}
            label='Login Session Expiry (seconds)'
            control={
              <InputGroup size='lg'>
                <ChakraInput
                  name='token_expiry_seconds'
                  defaultValue={token_expiry_seconds}
                  onChange={(e) =>
                    form.setValue('token_expiry_seconds', e.target.value)
                  }
                  size='lg'
                  placeholder='Enter Token Expiry time (card entry) in seconds'
                />
              </InputGroup>
            }
          />
        </GridItem>
        <GridItem />
        <GridItem colSpan={2}>
          <Checkbox
            isChecked={inactive}
            size='lg'
            onChange={(e) => {
              form.setValue('inactive', e.target.checked)
            }}
          >
            Set as Inactive
          </Checkbox>
        </GridItem>
      </Grid>
    </Form>
  )
}
