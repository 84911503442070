import { useState, useEffect } from 'react'
import { Buffer } from 'buffer'

interface IUseReportViewer {
  title: string
  name?: string
  active?: boolean
  data?: any[]
}

export const getMimeType = (type: string): string => {
  let mType
  if (type === 'pdf') mType = 'application/pdf'
  else if (type === 'zip') mType = 'application/zip'
  else if (type === 'stream') mType = 'application/octet-stream'
  else mType = 'text/plain;charset=utf-8'
  return mType
}

const getReportUrl = async (dt: any[], setUrl: any) => {
  //window.Buffer = window.Buffer || require('buffer').Buffer
  //IF VIEWER (WHERE view key returned from server) RETURN TO VIEWER URL
  let blob = Buffer.from(dt)
  let mimeType = getMimeType('pdf')
  const url = await window.URL.createObjectURL(
    new Blob([blob], { type: mimeType })
  )
  //setUrl(url)
  setUrl(url)
}

export const useReportViewer = (args: IUseReportViewer) => {
  let opts = {
    title: args.title || 'Report',
    active: false,
    data: [],
  }

  const [state, setState] = useState({ ...opts, ...args })
  const [data, setData] = useState<any[]>([])
  const [url, setUrl] = useState<any | undefined>(null)

  useEffect(() => {
    if (state.active && state.data) {
      getReportUrl(state.data, setUrl)
    }
  }, [state.active, state.data])

  const set = (obj: any) => {
    setState((prevState: any) => {
      return { ...prevState, ...obj }
    })
  }
  const setActive = (flag: boolean) => {
    setState((prevState: any) => {
      return { ...prevState, active: flag }
    })
  }

  return {
    ...state,
    data,
    setData,
    url,
    set,
    setActive,
  }
}
