import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Breadcrumbs } from 'Library/Chunks'

interface IHeaderProps {
  headerText: string | JSX.Element
  breadcrumbs?: boolean
  children?: React.ReactNode
}

const Header: React.FC<IHeaderProps> = (props) => {
  const { headerText, breadcrumbs = true, children, ...rest } = props

  return (
    <Flex mx={3} my={1} flexDirection='column' paddingTop='1' {...rest}>
      {breadcrumbs ? <Breadcrumbs /> : <></>}
      <Text
        fontSize='24px'
        fontWeight={600}
        //color='black'
        display='flex'
        alignItems='center'
        height='42px'
        {...rest}
      >
        {headerText}
      </Text>
    </Flex>
  )
}

export default Header
