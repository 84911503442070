import { AspectRatio, AspectRatioProps } from '@chakra-ui/react'
import React from 'react'
import { GuestPointNewLogo } from './SVG'

interface Props extends AspectRatioProps {
  width?: number
  color?: string
  src?: string | File
}

export const Logo: React.FC<Props> = (props) => {
  const { color, width } = props
  return (
    <AspectRatio minW={width || 127} ratio={127 / 32}>
      <GuestPointNewLogo color={color} />
    </AspectRatio>
  )
}
