import React from 'react'
import { Text, Box, Input, Grid, GridItem } from '@chakra-ui/react'
import numeral from 'numeral'
import _get from 'lodash/get'
import { useAuth, IUser, IUseAuthType } from 'Auth'

import { getNumber } from 'Library'

const TransactionFees = (props: any) => {
  const { data } = props
  const user: IUser = useAuth((state: IUseAuthType) => state.user)

  let expected_gateway = getNumber(_get(data, 'expected_gateway', 0))
  let expected_transaction = getNumber(_get(data, 'expected_transaction'))
  let expected_surcharge = getNumber(_get(data, 'expected_surcharge'))
  let expected_total =
    expected_gateway + expected_transaction + expected_surcharge
  let settle_gateway = getNumber(_get(data, 'settle_gateway'))
  let settle_transaction = getNumber(_get(data, 'settle_transaction'))
  let settle_surcharge = getNumber(_get(data, 'settle_surcharge'))
  let settle_total = settle_gateway + settle_transaction + settle_surcharge

  let isAdmin = user && user.type === 'ADMIN'
  return (
    <Box bg='aliceblue' maxWidth='800px' borderRadius='5px' p={2} my={2} mx={1}>
      <Grid
        gap='8px'
        templateColumns={isAdmin ? '2fr 1fr 1fr 1fr' : '2fr 1fr'}
        borderRadius='5px'
      >
        <GridItem fontWeight='bold'>Fees</GridItem>
        {isAdmin && (
          <GridItem fontWeight='bold' textAlign={'right'} pr={2}>
            Expected
          </GridItem>
        )}
        <GridItem fontWeight='bold' textAlign={'right'} pr={2}>
          Settlement
        </GridItem>
        {isAdmin && (
          <GridItem fontWeight='bold' textAlign={'right'} pr={2}>
            Variance
          </GridItem>
        )}

        <GridItem>Gateway Fee</GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(expected_gateway).format('0,00.00')}
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}
        <GridItem>
          <Input
            defaultValue={numeral(settle_gateway).format('0,00.00')}
            readOnly={true}
            textAlign={'right'}
          />
        </GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(settle_gateway - expected_gateway).format(
                '0,00.00'
              )}
              color={settle_gateway - expected_gateway >= 0 ? 'green' : 'red'}
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}

        <GridItem>Transaction Fee</GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(expected_transaction).format('0,00.00')}
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}
        <GridItem>
          <Input
            defaultValue={numeral(settle_transaction).format('0,00.00')}
            readOnly={true}
            textAlign={'right'}
          />
        </GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(
                settle_transaction - expected_transaction
              ).format('0,00.00')}
              color={
                settle_transaction - expected_transaction >= 0 ? 'green' : 'red'
              }
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}

        <GridItem>Surcharge</GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(expected_surcharge).format('0,00.00')}
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}

        <GridItem>
          <Input
            defaultValue={numeral(settle_surcharge).format('0,00.00')}
            readOnly={true}
            textAlign={'right'}
          />
        </GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(
                settle_surcharge - expected_surcharge
              ).format('0,00.00')}
              color={
                settle_surcharge - expected_surcharge >= 0 ? 'green' : 'red'
              }
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}
        {data && data.reason && (
          <>
            <GridItem py={2}>Surcharge Reason</GridItem>
            <GridItem colSpan={isAdmin ? 3 : 1}>
              <Text as='i' py={2}>
                {data.reason}
              </Text>
            </GridItem>
          </>
        )}

        <GridItem fontWeight='bold'>Total Fees</GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(expected_total).format('$0,0.00')}
              readOnly={true}
              fontWeight='bold'
              textAlign={'right'}
            />
          </GridItem>
        )}
        <GridItem>
          <Input
            defaultValue={numeral(settle_total).format('$0,0.00')}
            readOnly={true}
            fontWeight='bold'
            textAlign={'right'}
          />
        </GridItem>
        {isAdmin && (
          <GridItem>
            <Input
              defaultValue={numeral(settle_total - expected_total).format(
                '0,00.00'
              )}
              fontWeight='bold'
              color={settle_total - expected_total >= 0 ? 'green' : 'red'}
              readOnly={true}
              textAlign={'right'}
            />
          </GridItem>
        )}
      </Grid>
      {data && data.notice && <Text mt={2}>Notice: {data.notice}</Text>}
    </Box>
  )
}

export default React.memo(TransactionFees)
