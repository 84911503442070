export const _search = (arr: [], text: string) => {
  text = String(text).toLowerCase()
  let result: object[] = []
  for (let i = 0; i < arr.length; i++) {
    let keys = Object.keys(arr[i])
    for (let idx in keys) {
      let val = arr[i][keys[idx]]
      let str
      if (typeof val === 'number') str = String(val).trim()
      else if (typeof val === 'string') str = val
      else if (typeof val === 'object') str = String(val).trim()
      if (str) {
        str = String(str).toLowerCase()
        if (str.indexOf(text) >= 0) {
          result.push(arr[i])
          break
        }
      }
    }
  }
  return result
}
