import React, { useEffect, useMemo } from 'react'
import numeral from 'numeral'
import moment from 'moment'
import { Box, GridItem, Button, Text } from '@chakra-ui/react'
import CRUD, {
  useCRUD,
  Alert,
  useAlert,
  Table,
  FilterInputSearch,
  FilterInputDateRange,
  FilterInputSelect,
  Filters,
  BtnRefresh,
  MultiLineHeader,
} from 'Library/CRUD'
import { FaEye } from 'react-icons/fa'

import { useDB, IUseDBType } from 'Library/DB'
import { useStore } from 'Store'
import { getSelect } from 'Library'
import { TransactionDrilldown } from '../Transactions'
import { useReportViewer, ReportViewer } from 'Library/ReportViewer'
import { useAuth, IUser, IUseAuthType } from 'Auth'

const Settlements = () => {
  const db = useDB()
  const user: IUser = useAuth((state: IUseAuthType) => state.user)
  let multiMerchant = user.type === 'ADMIN' ? true : false

  const [alert, setAlert] = useAlert()

  let lookup = useStore((state: any) => state.lookup)
  let merchantSelect = getSelect(lookup.merchant, {
    keyField: 'code',
    label: 'code',
    selectAll: { value: '', label: 'All' },
  })

  let report = useReportViewer({ title: 'Settlement Report' })

  const crud = useCRUD({
    id: 'settlement',
    title: user.mode === 'API' ? '' : 'Settlements',
    hasEditor: false,
    keyField: 'id',
    setAlert: setAlert,
    waitRefresh: true,
    filter: {
      startDate: moment().startOf('month').toDate(),
      endDate: moment().endOf('month').toDate(),
      type: '',
      merchant: '',
    },
    fetch: async (qry: string) => {
      // if (!filter.query) return [] //Wait until there is a filter
      let result: IUseDBType = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/settlement/fetch${qry || ''}`,
      })
      return result.data
    },
  })
  const { updateFilter, refresh } = crud

  //FILTER ON CHANGE OF USER MERCHANT CODE (IF LOGIN USER CHANGES)
  useEffect(() => {
    updateFilter({ merchant: user.merchant })
  }, [updateFilter, user.merchant])

  //REFRESH CRUD IF THERE IS A CHANGE IN DB TOKEN (CAN HAPPEN WITH EMBEDDED CLIENT)
  useEffect(() => {
    refresh()
  }, [refresh, db.token])

  const viewReport = React.useCallback(
    async (id: string) => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/report/settlement/${id}`,
      })
      report.set({ data: result.data.data, active: true })
    },
    [db, report]
  )

  let columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: (row: any) => row['id'],
        width: '230px',
        sortable: true,
      },
      {
        name: 'Ref',
        selector: (row: any) => row['provider_ref'],
        width: '90px',
        sortable: true,
      },
      {
        name: 'Date',
        selector: (row: any) => row['date'],
        format: (row: any) => moment(row.date).format('DD/MM/YYYY'),
        width: '85px',
        sortable: true,
      },
      {
        name: 'Merchant',
        selector: (row: any) => row['merchant_id'],
        sortable: true,
        width: '110px',
      },
      {
        name: '',
        selector: (row: any) => row['merchant_id'],
        format: (row: any) => '',
        sortable: true,
      },
      {
        name: 'Gross',
        selector: (row: any) => row['gross'],
        sortable: false,
        format: (row: any) => numeral(row.gross).format('0,0.00'),
        width: '100px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Settled', 'Fees']} align='right' />,
        selector: (row: any) => row['settle_fees'],
        sortable: false,
        format: (row: any) => numeral(row.settle_fees).format('0,0.00'),
        width: '100px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Expected', 'Fees']} align='right' />,
        selector: (row: any) => row['expected_fees'],
        sortable: false,
        format: (row: any) => (
          <Text
            color={row.expected_fees <= row.settle_fees ? 'green' : 'red'}
            fontWeight={row.expected_fees > row.settle_fees ? 'bold' : 'normal'}
          >
            {numeral(row.expected_fees).format('0,0.00')}
          </Text>
        ),
        width: '100px',
        right: true,
      },
      {
        name: <MultiLineHeader heading={['Settled', 'Net']} align='right' />,
        selector: (row: any) => row['net'],
        sortable: false,
        format: (row: any) => numeral(row.net).format('0,0.00'),
        width: '100px',
        right: true,
      },
      {
        name: 'View / Print',
        cell: (row: any) => (
          <Button
            variant='outline'
            size='xs'
            colorScheme='success'
            leftIcon={<FaEye color='green' />}
            onClick={() => {
              viewReport(row.id)
            }}
          >
            View / Print
          </Button>
        ),
        sortable: false,
        center: true,
        width: '110px',
      },
    ],
    [viewReport]
  )

  const filters = useMemo(() => {
    return (
      <Filters
        templateColumns={[
          '1fr',
          '1fr 1fr',
          '1fr 1fr',
          '1fr 1fr',
          `400px 250px ${multiMerchant ? '180px' : ''} 1fr 150px`,
        ]}
      >
        <FilterInputSearch crud={crud} />
        <FilterInputDateRange
          start={moment(crud.filter.startDate).toDate()}
          end={moment(crud.filter.endDate).toDate()}
          onChange={(dates: any) => {
            updateFilter({
              startDate: dates[0],
              endDate: dates[1],
            })
          }}
        />
        {multiMerchant && (
          <FilterInputSelect
            placeholder='Merchant...'
            defaultValue={crud.filter.merchant}
            width='150px'
            onChange={(val: string) => {
              updateFilter({ merchant: val })
            }}
            options={merchantSelect}
          />
        )}

        <GridItem />
        <BtnRefresh crud={crud} cascade={true} />
      </Filters>
    )
  }, [crud, updateFilter, merchantSelect, multiMerchant])

  const conditionalRowStyles = [
    {
      when: (row: any) => row.type === 'PAYMENT' && row.settled > 0,
      style: {
        color: 'green',
        backgroundColor: '#f3f8f3',
        fontWeight: 600,
      },
    },
    {
      when: (row: any) => row.type === 'PREAUTH' && row.net > 0,
      style: {
        color: 'purple',
        backgroundColor: '#f6f1f6',
        fontWeight: 600,
      },
    },
  ]

  return (
    <Box fontFamily={'Roboto Condensed'} h='100%'>
      <CRUD id='transactions' crud={crud} filters={filters}>
        <Alert alert={alert} setAlert={setAlert} />
        <ReportViewer report={report} />
        <Table
          id='bank_table'
          crud={crud}
          columns={columns}
          responsive
          expandableRows
          //expandableRowDisabled={(row: any) => row.count <= 1}
          expandableRowsComponent={TransactionDrilldown}
          expandableRowsComponentProps={{
            pl: '45px',
            parent: 'SETTLEMENT',
            refreshParent: crud.refresh, //Allows Refresh of this parent to be called by child
          }}
          conditionalRowStyles={conditionalRowStyles}
          striped={false}
        ></Table>
      </CRUD>
    </Box>
  )
}

export default React.memo(Settlements)
