import React from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
} from '@chakra-ui/react'
import { FaDatabase, FaRegClipboard } from 'react-icons/fa'

//GET FORMATTED ERROR TO OUTPUT TO KEYBOARD
const formatObject = (obj) => {
  let text = ''
  let keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i]
    if (typeof obj[key] === 'object') {
      text += formatObject(obj[key])
    } else {
      text += `${String(key + ':')
        .trim()
        .padEnd(10, ' ')}${obj[key]}\n`
    }
  }
  return text
}

export const Alert = (props) => {
  const { db } = props
  const onClose = () => {
    if (db.alert.status === 403) db.setLogout(true)
    db.clearAlert()
  }

  //AUTO LOGOUT FOR SESSION KEY EXPIRY
  if (alert.message === 'Session Key has Expired') db.setLogout(true)
  //if (alert.message === 'Incorrect or inactive api-key') db.setLogout(true)
  return (
    <Modal
      isOpen={db && db.alert && db.alert.error}
      onClose={onClose}
      borderRadius='5px'
      size='3xl'
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg='red.100' borderTopRadius={'5px'}>
          <Flex direction='row' alignItems={'center'}>
            <FaDatabase size='18' />
            <Text ml={2}>
              Database Error: [{db.alert.status}] {db.alert.title}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody py={5}>
          {db.alert.message && (
            <>
              <Text fontSize={'lg'}>
                An Error was encountered. Please review error log for more
                details
              </Text>
              {db.alert.identity && (
                <Text fontWeight='600' fontSize='lg' color='blue'>
                  Identity: {db.alert.identity}
                </Text>
              )}
              <Text fontWeight='600' fontSize='lg' color='#5d0f02'>
                Message: {db.alert.message}
              </Text>
            </>
          )}
        </ModalBody>

        <ModalFooter p={1} bg='gray.100' borderBottomRadius={'5px'}>
          <Button
            m={1}
            colorScheme='secondary'
            color='grey'
            variant='outline'
            leftIcon={<FaRegClipboard />}
            onClick={() => {
              let text = formatObject(db.alert)
              //let text = db.alert.map((rec, idx) => `${idx}: ${rec[idx]}\n`)
              navigator.clipboard.writeText(text)
            }}
          >
            COPY ERROR DETAILS
          </Button>
          <Button m={1} colorScheme='blue' onClick={onClose}>
            {db.alert.status === 403 ? 'Logout' : 'OK'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
