//Note: This is JSX component (not Typescript) as use of jsoneditor-react had issues using Types
import React, { useEffect } from 'react'
import {
  Grid,
  InputGroup,
  GridItem,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import './Templates.css'
import ace from 'brace'
import 'brace/mode/json'
import 'brace/theme/github'

import { Form, useForm, InputControl, Select } from 'Library/CRUD'
import { getSelect } from 'Library'
import { useStore } from 'Store'

export const TemplateEdit = (props) => {
  let { id, crud } = props
  let form = useForm()
  let { provider, tran_type, request_template, response_mapping } = form.watch()

  //APPLY CRUD RECORD VALUES TO FORM
  useEffect(() => {
    if (crud && crud.record && Object.keys(crud.record).length) {
      form.reset(crud.record)
    }
    // Override es-lint (We do not want to update everytime other parts of Crud Change)
    // eslint-disable-next-line
  }, [crud.record])

  let lookup = useStore((state: any) => state.lookup)
  let providerSelect = getSelect(lookup.provider, {
    keyField: 'id',
    label: 'name',
    //orderBy: ['label', 'desc'],
  })

  const onSubmit = async (e) => {
    // e.preventDefaullt()
    let result = await crud.submit(form.getValues())
    if (result && !result.error) crud.set({ active: false })
  }

  return (
    <Form
      form={form}
      crud={crud}
      id={id}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Tabs>
        <TabList>
          <Tab>Details</Tab>
          <Tab>Request Template</Tab>
          <Tab>Response Mapping</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid gap='8px' templateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
              <GridItem>
                <InputControl
                  id='provider'
                  form={form}
                  label='Provider'
                  validation={{
                    required: 'Provider is Required',
                  }}
                  control={
                    <InputGroup size='lg'>
                      <Select
                        name='provider'
                        defaultValue={provider}
                        options={providerSelect}
                        onChange={(val) => {
                          //crud.setRecord({ provider: val })
                          form.setValue('provider', val)
                        }}
                        size='lg'
                        placeholder='Select Provider...'
                      />
                    </InputGroup>
                  }
                />
              </GridItem>
              <GridItem />
              <GridItem>
                <InputControl
                  id='tran_type'
                  form={form}
                  label='Transaction Type'
                  validation={{
                    required: 'Transaction Type is Required',
                  }}
                  control={
                    <InputGroup size='lg'>
                      <Select
                        name='tran_type'
                        defaultValue={tran_type}
                        options={[
                          {
                            label: 'Payment',
                            value: 'PAYMENT',
                          },
                          {
                            label: 'Pre-Auth',
                            value: 'PREAUTH',
                          },
                          {
                            label: 'Refund',
                            value: 'REFUND',
                          },
                          {
                            label: 'Claim',
                            value: 'CLAIM',
                          },
                        ]}
                      />
                      onChange=
                      {(val) => {
                        //crud.setRecord({ tran_type: val })
                        form.setValue('tran_type', val)
                      }}
                      size='lg' placeholder='Select Provider...' />
                    </InputGroup>
                  }
                />
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            {request_template && (
              <>
                <Editor
                  value={request_template}
                  //ajv={ajv}
                  ace={ace}
                  theme='ace/theme/github'
                  mode='code'
                  statusBar={true}
                  //schema={schema}
                  allowedModes={['tree', 'code', 'text', 'form']}
                  onChange={(json) => form.setValue('request_template', json)}
                  //htmlElementProps={{ style: { height: '300px' } }}
                />
              </>
            )}
          </TabPanel>
          <TabPanel>
            {response_mapping && (
              <>
                <Editor
                  value={response_mapping}
                  ace={ace}
                  theme='ace/theme/github'
                  mode='code'
                  statusBar={true}
                  //schema={schema}
                  allowedModes={['tree', 'code', 'text']}
                  onChange={(json) => form.setValue('response_mapping', json)}
                  htmlElementProps={{ style: { height: '300px' } }}
                />
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Form>
  )
}
