import React from 'react'
import numeral from 'numeral'
import { Text } from '@chakra-ui/react'

export interface INumberFormat {
  amount: number
  format?: string
}

export const NumberFormat = (props: INumberFormat) => {
  let { amount, format } = props
  if (!format) format = '(0,0.00)'
  return (
    <Text color={amount < 0 ? 'red' : 'green'}>
      {numeral(amount).format(format)}
    </Text>
  )
}
