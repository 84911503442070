import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaSyncAlt } from 'react-icons/fa'

const BtnRefresh = (props) => {
  const { crud, cascade, label = 'Refresh', color, ...otherProps } = props
  return (
    <Button
      variant='outline'
      colorScheme='blackAlpha'
      leftIcon={<FaSyncAlt color='grey' />}
      id='btn-refresh'
      onClick={() => {
        crud.refresh({ message: 'Btn Filter Refresh' })
      }}
      {...otherProps}
    >
      {label}
    </Button>
  )
}

export default React.memo(BtnRefresh)
