import React, { useEffect, useState } from 'react'
import { Flex, Text, Box, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { FaCheck, FaFlag, FaTimes, FaHourglass } from 'react-icons/fa'
import _get from 'lodash/get'
import { useStore } from 'Store'
import { useDB } from 'Library/DB'
import { Select } from 'Library/CRUD'

interface IMerchantNotifications {
  setTabIndex?: (idx: number) => void
  setOptions: (obj: any) => void
  user: any
}

const getIcon = (icon: string, color: string) => {
  switch (icon) {
    case 'flag':
      return <FaFlag color={color} />
    case 'cross':
      return <FaTimes color={color} />
    case 'wait':
      return <FaHourglass color={color} />
    case 'check':
    default:
      return <FaCheck color={color} />
  }
}

interface INotification {
  description: string
  color: string
  count: string | number
  mode: string
  icon: string
  hyperlink: boolean
  startDate: string
  endDate: string
}

const Notification = (props: any) => {
  let data: INotification = props.data
  let user = props.user
  let setTabIndex = props.setTabIndex
  let setOptions = props.setOptions
  let startDate = props.startDate
  let endDate = props.endDate
  let icon = getIcon(data.icon, data.color)
  let navigate = useNavigate()

  if (!data.description || !parseInt(String(data.count))) return <></>
  let desc = data.description.split('{{COUNT}}')
  let description = data.hyperlink ? (
    <Flex display='inline-block' direction='row' alignItems={'center'}>
      <Text display='inline-flex' ml={2} mr={1}>
        {_get(desc, '[0]', '')}
      </Text>
      {data.count}
      {/* <Button
        ml={1}
        variant='outline'
        display='inline-flex'
        borderColor={data.color}
        size='xs'
        p={0}
        px={1}
        onClick={() => {
          //SET FILTERS FOR LINK TO TRANSACTION PAGE
          let filter: any = { mode: data.mode }
          if (data.mode !== 'is_pending') {
            filter.startDate = startDate //moment(data.startDate).toDate()
            filter.endDate = endDate //moment(data.endDate).toDate()
          }
          setOptions({
            transactionFilter: filter,
          })
          if (user && user.mode && user.mode === 'API') {
            setTabIndex(2)
          } else {
            navigate('/transactions')
          }
        }}
      >
        {data.count}
      </Button> */}
      <Text display='inline-flex' ml={1}>
        {_get(desc, '[1]', '')}
      </Text>
    </Flex>
  ) : (
    <Text ml={2}>
      {data.description.replace('{{COUNT}}', String(data.count))}
    </Text>
  )

  return (
    <Flex direction='row' my={1} alignItems={'center'}>
      {icon}
      {description}
    </Flex>
  )
}

const MerchantNotifications = (props: IMerchantNotifications) => {
  let { user, setOptions, setTabIndex } = props
  const db = useDB()
  let lookup = useStore((state: any) => state.lookup)

  const [data, setData] = useState({})
  const [period, setPeriod] = useState('TODAY')

  useEffect(() => {
    const getData = async () => {
      let result = await db.axios({
        server: 'PAYGATE',
        method: 'GET',
        url: `/api/management/dashboard/status/?period=${period}&merchant=${user.merchant}`,
      })
      setData(result.data)
    }
    if (user.merchant && period) getData()
    // esline-override - cannot pass db as dependency when data is retrieved
    // eslint-disable-next-line
  }, [user.merchant, period])

  return (
    <Flex h='100%' direction='column' alignItems='left' justifyContent='top'>
      <Flex direction='row'>
        <Text fontSize={'18px'} color='blue' fontWeight={600}>
          {_get(lookup, `merchant[${user.merchant}].name`, '')} Summary
        </Text>
      </Flex>
      <Flex direction='row' justifyContent={'left'}>
        <Box w='200px' mt={2}>
          <Select
            bg='white'
            name='class'
            defaultValue={'TODAY'}
            options={[
              { label: 'Today', value: 'TODAY' },
              { label: 'This Week', value: 'WEEK' },
              { label: 'Last Week', value: 'LASTWEEK' },
              { label: 'This Month', value: 'MONTH' },
              { label: 'Last Month', value: 'LASTMONTH' },
            ]}
            onChange={(val: any) => {
              setPeriod(val)
              //crud.setRecord({ class: val })
              //form.setValue('rateplan', val)
            }}
            size='md'
            width='200px'
          />
        </Box>
      </Flex>
      <Flex direction='column'>
        <Text size='22px' fontWeight={600} mt={2} mb={1}>
          Transactions
        </Text>
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'payments', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'refunds', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'preauth', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'claim', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Text size='22px' fontWeight={600} mt={2} mb={1}>
          Transaction Status
        </Text>
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'complete', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'pending', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'failed', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
        <Notification
          user={user}
          setTabIndex={setTabIndex}
          setOptions={setOptions}
          data={_get(data, 'errors', {})}
          startDate={_get(data, 'startDate', '')}
          endDate={_get(data, 'endDate', '')}
        />
      </Flex>
    </Flex>
  )
}

export default React.memo(MerchantNotifications)
