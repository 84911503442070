//Note: This is JSX component (not Typescript) as use of jsoneditor-react had issues using Types
import React, { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import ace from 'brace'
import 'brace/mode/json'
import 'brace/theme/github'
import { useDB } from 'Library/DB'

export const RequestDetail = (props) => {
  let { data } = props
  const db = useDB()
  const [json, setJson] = useState({})

  const getData = async () => {
    let details = await db.axios({
      server: 'PAYGATE',
      method: 'GET',
      url: `/api/management/request/fetchdetail/${data.id}`,
    })
    setJson(details)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [data.id])

  if (json && !Object.keys(json).length) return <></>
  return (
    <Box>
      <Editor
        value={json}
        ace={ace}
        theme='ace/theme/github'
        mode='code'
        statusBar={true}
        disabled={true}
        allowedModes={['tree', 'code', 'text', 'form']}
      />
    </Box>
  )
}
